export const jsonOr = (defaultValue) => (json) =>
  json ? JSON.parse(json) : defaultValue

export const toJson = (...args) => JSON.stringify(...args)

export const fromJson = (...args) => JSON.parse(...args)

export const isJson = (value) => {
  try {
    JSON.parse(value)
    return true
  } catch (error) {
    return false
  }
}
