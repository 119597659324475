import { componentColorPropType } from '@/theme'
import PropTypes from 'prop-types'
import { buttonSizePropType, buttonVariantPropType } from './propTypes'
import { Container } from './styles'

export const ButtonBase = ({
  color,
  size,
  variant = 'contained',
  disabled = false,
  type = 'button',
  ...rest
}) => (
  <Container
    color={color}
    disabled={disabled}
    size={size}
    type={type}
    variant={variant}
    {...rest}
  />
)

ButtonBase.propTypes = {
  color: componentColorPropType,
  disabled: PropTypes.bool,
  size: buttonSizePropType,
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
  variant: buttonVariantPropType,
}
