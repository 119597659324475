import { Collapse, Column } from '@/components'
import { viewInfo } from '@/ga'
import { Tick } from '@/icons/Tick'
import { useFormContext, useOnNextClick } from '@/providers'
import { paths } from '@/routes'
import { useGoToPath } from '@/utils'
import { calculator, toEur } from '../helpers'
import {
  StepContainer,
  StepCardWithInfoContainer,
  Card,
  CardHeader,
  AbsoluteTag,
  CollapseHeader,
  Step3TitleContainer,
  PriceTag,
} from '../styles'

const CollapseTitle = ({ title, price }) => (
  <CollapseHeader>
    <div>
      <Tick />
      {title}
    </div>
    <PriceTag>ab {toEur(price)} jährlich</PriceTag>
  </CollapseHeader>
)

export const Step3 = () => {
  const { values } = useFormContext()

  const { liability, content } = calculator(values)

  const goToSchedule = useGoToPath(paths.schedule)

  useOnNextClick(goToSchedule)

  return (
    <StepContainer>
      <Step3TitleContainer>
        Empfehlung für
        <br />
        <strong> Solide </strong> ☘️ Grundabsicherung
      </Step3TitleContainer>
      <Card>
        <CardHeader>
          ab<span> {toEur(liability + content)}</span>
          <Column>
            <span>jährlich inklusive</span>
            <span>Versicherungssteuer</span>
          </Column>
          <AbsoluteTag>💸 40% bis 60% Sparpotential</AbsoluteTag>
        </CardHeader>
        <StepCardWithInfoContainer>
          <span>Sparen Sie durch unsere Empfehlung!</span>
          <Collapse
            onOpen={() => viewInfo('liability')}
            title={
              <CollapseTitle
                title="Betriebshaftpflichtversicherung"
                price={liability}
              />
            }
          >
            Die Betriebshaftpflichtversicherung gehört zu den wichtigsten
            Absicherungen für Unternehmen, Selbstständige und Freiberufler. Wie
            im privaten Bereich müssen auch Betriebe in voller Höhe für vom
            Inhaber oder Mitarbeitern verursachten Schäden aufkommen. Die
            Betriebshaftpflichtversicherung übernimmt Personen-, Sach- oder
            Vermögensschäden und wehrt unberechtigt gestellte Ansprüche ab.
            <br />
            <br />
            <ul>
              <li>Gehört zu den wichtigsten Versicherungen für Unternehmen</li>
              <li>
                Schützt das Unternehmen und die Mitarbeiter vor
                Schadensersatzansprüchen Dritter
              </li>
              <li>
                Versichert gegen Personenschäden, Sachschäden,
                Vermögensfolgeschäden und Umweltschäden
              </li>
            </ul>
          </Collapse>
          <Collapse
            onOpen={() => viewInfo('content')}
            title={
              <CollapseTitle title="Inhaltsversicherung" price={content} />
            }
          >
            Die Geschäftsinhaltsversicherung ist das geschäftliche Äquivalent
            zur Hausratversicherung. Versichert sind neben der vorhandenen
            Betriebseinrichtung auch gelagerte Waren und Vorräte. Kommt es zu
            einem Schaden durch Brand, Explosion, Blitzschlag, Einbruch,
            Diebstahl, Vandalismus, Leitungswasser oder Sturm, so ersetzt der
            Versicherer in der Regel den Neuwert der versicherten Sachen oder
            kommt alternativ für deren Instandsetzung auf. Ohne
            Geschäftsinhaltsversicherung können solche Schäden schnell zur
            Insolvenz des betroffenen Unternehmens führen.
            <br />
            <br />
            <ul>
              <li>
                Grundlegende Versicherung für Einrichtung, Geräte und Vorräte
                gegen bspw. Feuer
              </li>
              <li>
                Versichert gegen Einbruch und Vandalismus sowie Schäden durch
                Umwelteinflüsse (Brand, Blitzschlag, Sturm und Hagel) oder
                Leitungswasser
              </li>
              <li>Erweiterbar um Betriebsunterbrechungsversicherung</li>
            </ul>
          </Collapse>
        </StepCardWithInfoContainer>
      </Card>
    </StepContainer>
  )
}
