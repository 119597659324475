import { api } from './api'

export const notify = ({
  business,
  revenue,
  contentValue,
  name,
  phone,
  email,
}) =>
  api.post('/request/insurance_check', {
    business,
    revenue,
    contentValue,
    name,
    phone,
    email,
  })
