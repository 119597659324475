import { useCallback } from 'react'
import { Select, selectChildrenPropType } from '../../atoms'
import { FormItem } from './Component'
import { formItemPropType } from './helpers'

export const FormSelect = ({
  name,
  label,
  children,
  initialValue,
  info,
  ...props
}) => {
  const Component = useCallback(
    ({ onChange, ...field }) => (
      <Select onSelect={onChange} {...field} {...props}>
        {children}
      </Select>
    ),
    [],
  )

  return (
    <FormItem info={info} initialValue={initialValue} label={label} name={name}>
      <Component />
    </FormItem>
  )
}

FormSelect.propTypes = {
  children: selectChildrenPropType,
  ...formItemPropType,
}
