import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
  revenue: Yup.number()
    .min(1000, 'Mindestbetrag darf nicht kleiner als 1000 EUR sein')
    .max(2000000, 'Höchstbetrag darf nicht über 2 Millionen sein')
    .required('Pflichtfeld')
    .typeError('Pflichtfeld'),
  contentValue: Yup.number()
    .min(1000, 'Mindestbetrag darf nicht kleiner als 1000 EUR sein')
    .max(500000, 'Höchstbetrag darf nicht über 500.000 EUR sein')
    .required('Pflichtfeld')
    .typeError('Pflichtfeld'),
  name: Yup.string().required('Bitte Name eingeben'),
  phone: Yup.string()
    .required('Bitte Rufnummer eingeben')
    .matches(
      /^(\+49\s?)?((\(\d{3,5}\)|\d{3,5})(\s)?)\d{3,8}$/,
      'Die Rufnummer ist nicht korrekt',
    ),
  email: Yup.string()
    .email('Die Mail Adresse ist nicht korrekt')
    .required('Bitte eine Mail Adresse eingeben'),
  terms: Yup.number().min(1, 'Du musst unsere AGBs akzeptieren').required(),
  business: Yup.string()
    .oneOf(
      ['restaurant', 'bar', 'cafe', 'kantine', 'eisdiele', 'imbiss', 'andere'],
      'Bitte eine Option auswählen',
    )
    .required('Bitte eine Option auswählen'),
})
