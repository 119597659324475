import PropTypes from 'prop-types'
import { Image } from '../Image'
import { Container } from './styles'

export const Loader = ({ fill }) => (
  <Container fill={fill}>
    <Image name="logo" type="gif" />
  </Container>
)

Loader.propTypes = {
  fill: PropTypes.bool,
}
