import { ThemeColor } from '@/enums'
import { WarningIcon } from '@/icons'
import { useFormContext, useFormItem } from '@/providers'
import { isUndefined, oneChild } from '@/utils'
import { cloneElement, useEffect, useState } from 'react'
import { InfoTooltip } from '../InfoTooltip'
import { formItemPropType, getError } from './helpers'
import {
  Container,
  StyledTypography,
  ErrorMessage,
  LabelContainer,
} from './styles'

export const FormItem = ({
  name,
  label,
  initialValue = '',
  info,
  children,
}) => {
  const { setFieldValue, validateField } = useFormContext()
  const { value, onChange, error } = useFormItem(name)
  const [visited, setVisited] = useState(false)

  const clonedChildren = cloneElement(children, {
    value,
    error: Boolean(error),
    onChange,
    onFocus: !visited ? () => setVisited(true) : undefined,
  })

  useEffect(() => {
    if (isUndefined(value)) setFieldValue(name, initialValue)
  }, [])

  useEffect(() => {
    if (visited) validateField(name)
  }, [value])

  return (
    <Container>
      {(label || info) && (
        <LabelContainer>
          <StyledTypography
            color={ThemeColor.b0}
            htmlFor={name}
            variant="inputLabel"
          >
            {label}
          </StyledTypography>
          {info && <InfoTooltip content={info} />}
        </LabelContainer>
      )}
      {clonedChildren}
      {error && (
        <ErrorMessage>
          <WarningIcon />
          {getError(error)}
        </ErrorMessage>
      )}
    </Container>
  )
}

FormItem.propTypes = {
  children: oneChild.isRequired,
  ...formItemPropType,
}
