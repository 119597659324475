import {StyledBurger} from "@/components/atoms/Burger/styles";
import {bool, func} from "prop-types";

export const Burger = ({open, setOpen}) => {
  return (
    <StyledBurger open={open} onClick={() => setOpen(!open)}>
      <div />
      <div />
    </StyledBurger>
)};

Burger.propTypes = {
  open: bool.isRequired,
  setOpen: func.isRequired,
}