import { Tick } from '@/icons'
import { SectionTitle, UspsContainer, UspsSection } from './styles'

export const USPS = () => (
  <UspsSection>
    <SectionTitle>
      Unser <strong>Versprechen</strong> 🛡
    </SectionTitle>
    <UspsContainer>
      {[
        '100% unabhängig und 100% in deinem Interesse!',
        'Bester Marktpreis für deinen Versicherungsschutz!',
        'Kostenfreier Vergleich aus über 100 Anbietern',
        'Kompletter Versicherungsschutz auf einen Blick',
      ].map((item) => (
        <div>
          <Tick size="button" />
          {item}
        </div>
      ))}
    </UspsContainer>
  </UspsSection>
)
