import { ThemeColor } from '@/enums'
import { themeColor, toThemePixels } from '@/utils'
import styled from 'styled-components'
import { ifProp } from 'styled-tools'
import { Row } from '../../styled'

export const Container = styled(Row)`
  z-index: 10000;
  box-sizing: border-box;
  width: 100vw;
  height: 56px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${themeColor(ThemeColor.b0)};
  padding: ${toThemePixels(3)} ${toThemePixels(14)};
  justify-content: ${ifProp('centered', 'center', 'space-between')};
  align-items: center;
  box-shadow: 0px 6px 12px rgba(11, 44, 93, 0.05),
    0px 0px 12px rgba(11, 44, 93, 0.05);

  svg {
    cursor: pointer;
  }
`
