import styled from 'styled-components'

export const DotlessList = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;

  @media (max-width: 768px) {
    flex-direction: column;
    font-size: 20px;
    line-height: 25px;
    padding: 0;
  }
`

export const DotlessListHorizontal = styled(DotlessList)`
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  margin: 0;

  @media (max-width: 768px) {
    margin: 1rem 0;
    font-size: 1rem;
    display: block;
  }
`
