import { ThemeColor } from '@/enums'
import {
  primaryColorFromComponentColor,
  secondaryColorFromComponentColor,
  toThemePixels,
} from '@/utils'
import styled from 'styled-components'

const colorSwitchProp = primaryColorFromComponentColor(ThemeColor.b100)

const backgroundColorSwitch = secondaryColorFromComponentColor(
  ThemeColor.background2,
)

export const Container = styled.div`
  color: ${colorSwitchProp};
  fill: ${colorSwitchProp};
  background-color: ${backgroundColorSwitch};
  border: 1px solid ${colorSwitchProp};
  padding: 0px ${toThemePixels(2)};
  line-height: 19px;
  height: 24px;
  width: fit-content;
  border-radius: ${toThemePixels(3.5)};
  display: flex;
  column-gap: ${toThemePixels(1)};
  align-items: center;
`
