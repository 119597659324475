import { ThemeColor } from '@/enums'
import { themeTransition, toPixels, toThemePixels } from '@/utils'
import { pipe } from 'ramda'
import styled from 'styled-components'
import { prop } from 'styled-tools'

export const Container = styled.div`
  background-color: rgba(255, 255, 255, 0.15);
  width: ${pipe(prop('width'), toPixels)};
  height: 4px;
  border-radius: ${toThemePixels(1)};
  box-shadow: 0px 6px 12px rgba(11, 44, 93, 0.05),
    0px 0px 12px rgba(11, 44, 93, 0.05);
`

export const InnerContainer = styled.div`
  width: ${prop('width')}%;
  height: 4px;
  border-radius: ${toThemePixels(1)};
  background-color: #fff;
  transition: ${themeTransition('width')};
`
