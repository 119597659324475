import { curry, ifElse, complement, prepend, __, append } from 'ramda'
import { notEmptyOrFalsy } from './bool'
import { returns } from './function'
import { isArray, isUndefined } from './type'

export const prependSafe = curry((el, list) =>
  ifElse(complement(isUndefined), prepend(__, list), returns(list))(el),
)

export const appendSafe = curry((el, list) =>
  ifElse(complement(isUndefined), append(__, list), returns(list))(el),
)

export const pack = (x) => {
  if (!isArray(x)) return [x]
  return x
}

export const safePack = ifElse(notEmptyOrFalsy, pack, returns(undefined))
