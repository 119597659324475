import { Button, FormSelect, WithGap, Option } from '@/components'
import { useGoToPath } from '@/utils'
import { useFormContext, useFormItem } from '@/providers'
import {
  WelcomeImage,
  WelcomeSection,
  WelcomeTitle,
  Dummy,
  WelcomeSelect,
  WelcomeOptions,
} from './styles'
import { startCampaign } from '@/ga'

export const Welcome = () => {
  const { validateField } = useFormContext()
  const { value } = useFormItem('business')
  const goToCampaign = useGoToPath('/insurance-check')

  const handleClick = () => {
    if (value) {
      goToCampaign()
      startCampaign('a')
    } else validateField('business')
  }

  return (
    <WelcomeSection>
      <Dummy>
        <div />
      </Dummy>
      <WithGap gap={9}>
        <WelcomeTitle>
          Deine <strong>Gastro🥂</strong>: individuell versichert zum besten
          Preis!
        </WelcomeTitle>
        <p>
          Dein zuverlässiger Partner für den Schutz deiner Gastro! SureIn
          vergleicht für dich über 100 Anbieter! Zusammen mit deinem Berater
          erhältst du den optimalen Mix aus Service & Preis!
        </p>
        <WelcomeOptions gap="9px">
          <WelcomeSelect>
            <FormSelect
              id="business"
              name="business"
              placeholder="Betriebsart auswählen"
            >
              <Option key="restaurant" value="restaurant">
                Restaurant
              </Option>
              <Option key="bar" value="bar">
                Bar
              </Option>
              <Option key="cafe" value="cafe">
                Café
              </Option>
              <Option key="kantine" value="kantine">
                Kantine
              </Option>
              <Option key="eisdiele" value="eisdiele">
                Eisdiele
              </Option>
              <Option key="imbiss" value="imbiss">
                Imbiss
              </Option>
              <Option key="andere" value="andere">
                Andere
              </Option>
            </FormSelect>
          </WelcomeSelect>
          <Button color="success" onClick={handleClick}>
            Check Starten
          </Button>
        </WelcomeOptions>
      </WithGap>
      <WelcomeImage />
    </WelcomeSection>
  )
}
