import { filter } from 'ramda'
import GA from 'react-ga4'
import { config } from './config'
import { fromJson, isProd } from './utils'

export const initGA = () => {
  if (isProd()) GA.initialize(config.ga)
}

const gaEvent = ({ action, category = 'insurance_check', value, label }) => {
  if (isProd()) GA.event(filter(Boolean, { action, category, value, label }))
}

const trackOnce = ({ action, category = 'insurance_check', value, label }) => {
  let storedKeys = localStorage.getItem('ga-keys')
  if (storedKeys) storedKeys = fromJson(storedKeys)

  if (
    !storedKeys ||
    !storedKeys.length ||
    !storedKeys.includes(`${category}-${action}-${label}`)
  ) {
    gaEvent({ action, category, value, label })
    localStorage.setItem(
      'ga-keys',
      JSON.stringify([...(storedKeys || []), `${category}-${action}-${label}`]),
    )
  }
}

export const startCampaign = (value) =>
  gaEvent({ action: 'start', label: 'ab', value })

export const inputContent = (value) =>
  trackOnce({ action: 'input', label: 'content', value })

export const inputRevenue = (value) =>
  trackOnce({ action: 'input', label: 'revenue', value })

export const inputContact = () =>
  gaEvent({ action: 'input', label: 'contact_info' })

export const viewResults = () => gaEvent({ action: 'view', label: 'results' })

export const viewInfo = (value) =>
  gaEvent({ action: 'view', label: 'information', value })

export const clickBook = () =>
  gaEvent({ aciton: 'click', value: 'appointment' })

export const clickCall = () => gaEvent({ aciton: 'click', value: 'call' })
