import { oneChild, renderableType, useListenHover } from '@/utils'
import PropTypes from 'prop-types'
import { cloneElement } from 'react'
import { Container, HoverContent } from './styles'

export const Tooltip = ({ content, position = 'top', children }) => {
  const { isOpen, onMouseEnter, onMouseLeave } = useListenHover()

  const clonedChildren = cloneElement(children, {
    onMouseEnter,
    onMouseLeave,
  })

  return (
    <Container>
      {isOpen && <HoverContent position={position}>{content}</HoverContent>}
      {clonedChildren}
    </Container>
  )
}

Tooltip.propTypes = {
  children: oneChild.isRequired,
  content: renderableType.isRequired,
  position: PropTypes.oneOf(['top', 'left', 'right', 'bottom']),
}
