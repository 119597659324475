import { dynamicObjectPropType, noop, oneOrManyChildren } from '@/utils'
import { Formik, Form, useField, useFormikContext } from 'formik'
import PropTypes from 'prop-types'
import { isEmpty, ifElse, prop, __ } from 'ramda'

export const FormProvider = ({
  children,
  initialValues = {},
  onSubmit,
  ...props
}) => (
  <Formik
    initialValues={initialValues}
    onSubmit={onSubmit}
    validateOnChange={false}
    {...props}
  >
    <Form>{children}</Form>
  </Formik>
)

FormProvider.propTypes = {
  children: oneOrManyChildren.isRequired,
  initialValues: dynamicObjectPropType,
  onSubmit: PropTypes.func,
}

export const useFormItem = (name) => {
  const [, { value, error }, { setValue }] = useField({ name })

  return { value, onChange: setValue, error }
}

export const useFormContext = () => {
  const formikContext = useFormikContext()

  const customValidate = ({ onSuccess, onFail = noop }) =>
    formikContext.validateForm().then(ifElse(isEmpty, onSuccess, onFail))

  const value = prop(__, formikContext.values)

  return { customValidate, value, ...formikContext }
}
