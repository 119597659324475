import { ThemeColor } from '@/enums'
import { themeColor, themeTransition, toThemePixels } from '@/utils'
import styled from 'styled-components'
import { ifNotProp, ifProp, prop } from 'styled-tools'
import { Row } from '../../styled'

export const Container = styled.div``

export const CardSelectContainer = styled(Row)`
  flex-wrap: wrap;
  gap: ${toThemePixels(5)};
`
export const StyledOption = styled.div`
  width: ${prop('width')}%;
  border-radius: ${toThemePixels(1)};
  cursor: pointer;
  border: 1px solid
    ${ifProp(
      'error',
      themeColor(ThemeColor.danger1),
      ifProp('selected', 'transparent', themeColor(ThemeColor.b50)),
    )};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${toThemePixels(4)};
  transition: ${themeTransition('background-color')};
  background-color: ${ifProp('selected', themeColor(ThemeColor.primary1))};
  color: ${ifProp('selected', themeColor(ThemeColor.b0))};

  &:hover {
    background-color: ${ifNotProp(
      'selected',
      themeColor(ThemeColor.background1),
    )};
  }

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
