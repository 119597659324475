import { toThemePixels } from '@/utils'
import styled from 'styled-components'
import { ifProp } from 'styled-tools'
import { ButtonBase } from '../ButtonBase'

export const Container = styled(ButtonBase)`
  display: flex;
  gap: ${toThemePixels(1.5)};
  align-items: center;
  flex-direction: ${ifProp('reverted', 'row-reverse')};
`
