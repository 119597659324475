import { ThemeColor } from '@/enums'
import { themeColor, themeTransition, toThemePixels } from '@/utils'
import styled from 'styled-components'
import { ifProp } from 'styled-tools'
import { Square } from '../../styled'

export const InnerContainer = styled(Square).attrs({
  $size: 16,
})`
  cursor: pointer;
  border: 1px solid ${themeColor(ThemeColor.b0)};
  border-color: ${ifProp('error', themeColor(ThemeColor.danger1))};
  border-radius: ${toThemePixels(1)};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: ${themeTransition()};
`

export const OuterContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${toThemePixels(1.5)};
  width: fit-content;
`
