export const ThemeColor = {
  primary1: 'primary1',
  primary2: 'primary2',
  success1: 'success1',
  success2: 'success2',
  warning1: 'warning1',
  warning2: 'warning2',
  danger1: 'danger1',
  danger2: 'danger2',
  background1: 'background1',
  background2: 'background2',
  b100: 'b100',
  b50: 'b50',
  b0: 'b0',
}

export const ThemeFontWeight = {
  light: 'light',
  regular: 'regular',
  medium: 'medium',
  bold: 'bold',
}

export const BreakpointType = {
  up: 'up',
  down: 'down',
  only: 'only',
  between: 'between',
}

export const Breakpoint = {
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
}

export const ComponentColors = {
  primary: 'primary',
  success: 'success',
  warning: 'warning',
  danger: 'danger',
  text: 'text',
}
