import { ThemeColor } from '@/enums'
import { DownLine } from '@/icons'
import { themeColor, themeTransition, toThemePixels } from '@/utils'
import styled from 'styled-components'
import { ifProp, prop } from 'styled-tools'
import { Row } from '../../styled'

export const Container = styled.div`
  background-color: ${themeColor(ThemeColor.b0)};
  color: ${themeColor(ThemeColor.b100)};
  border-top: 1px solid #edf1ff;
`

export const CollapseHeader = styled(Row)`
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  padding: ${toThemePixels(4)};

  h4 {
    margin: 0;
  }
`

export const CollapseIcon = styled(DownLine)`
  color: ${themeColor(ThemeColor.primary1)};
  width: 14px;
  height: 8px;
  transform: ${ifProp('$isOpen', 'rotate(180deg)')};
  transition: ${themeTransition('transform')};
`

export const CollapseBody = styled.div`
  overflow: hidden;
  height: ${ifProp('isOpen', prop('height'), 0)}px;
  transition: ${themeTransition('height')};
`

export const CollapseContent = styled.div`
  padding: ${toThemePixels(4)};
  font-weight: 400;
`
