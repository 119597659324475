import { ThemeColor } from '@/enums'
import { DownLine } from '@/icons'
import { themeColor, themeTransition, toThemePixels } from '@/utils'
import styled, { keyframes } from 'styled-components'
import { ifNotProp, ifProp } from 'styled-tools'
import { Input, Row } from '../../styled'

export const DropdownIcon = styled(DownLine).attrs({ size: 'menu' })`
  position: absolute;
  top: ${toThemePixels(5)};
  right: ${toThemePixels(2)};

  transition: ${themeTransition()};
`

export const Container = styled.div`
  width: 100%;
  position: relative;

  ${Input} {
    background-color: ${ifProp('isOpen', themeColor(ThemeColor.b0))};
    color: ${ifProp('isOpen', themeColor(ThemeColor.b100))};
  }

  ${DropdownIcon} {
    transform: ${ifProp('isOpen', 'rotate(180deg)')};
    top: ${ifProp('isOpen', toThemePixels(4))};

    path {
      stroke: ${ifProp(
        'isOpen',
        themeColor(ThemeColor.primary1),
        themeColor(ThemeColor.b0),
      )};
    }
  }
`

export const InputContainer = styled.div`
  width: inherit;
  position: relative;
  cursor: pointer;

  ${Input} {
    border-color: ${ifNotProp('error', 'white !important')};
    padding-right: ${toThemePixels(6)};
  }
`

const showOptions = keyframes`
  from {
    opacity: 0;
    max-height: 0px;
  }
`

export const OptionsContainer = styled.div`
  border: ${ifProp('error', '1px solid #FF5656')};
  border-top: none;
  position: absolute;
  left: 0;
  margin-top: -8px;
  padding-top: 8px;
  max-height: 216px;
  width: 100%;
  z-index: 10000;
  background-color: ${themeColor(ThemeColor.b0)};
  border-radius: 0 0 ${toThemePixels(2)} ${toThemePixels(2)};
  overflow: hidden;
  overflow-y: auto;
  animation: ${showOptions} 0.5s;
`

export const StyledOption = styled(Row)`
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  gap: ${toThemePixels(1)};
  padding: ${toThemePixels(4)};
  transition: ${themeTransition()};

  cursor: pointer;

  span {
    font-weight: ${ifProp('selected', 500)};
  }
  color: ${themeColor(ThemeColor.b100)};
  background-color: ${ifProp('selected', themeColor(ThemeColor.background1))};

  &:hover {
    background-color: ${themeColor(ThemeColor.background2)};
  }
`
