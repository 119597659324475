import { themeColorPropType } from '@/theme'
import { objectLiteral, propTypeFromEnumKeys, useThemeColor } from '@/utils'
import PropTypes from 'prop-types'

const buttonSize = {
  width: 24,
  height: 24,
}

const titleSize = {
  width: 22,
  height: 22,
}

const infoSize = {
  width: 16,
  height: 16,
}

const menuSize = {
  width: 14,
  height: 14,
}

const headerSize = {
  width: 32,
  height: 32,
}

const tagSize = {
  width: 13,
  height: 13,
}

const sizeMap = {
  button: buttonSize,
  info: infoSize,
  menu: menuSize,
  header: headerSize,
  tag: tagSize,
  title: titleSize,
}

const getSize = objectLiteral(sizeMap)

export const withSvgProps = (Component) => {
  const SvgWithProps = ({
    color, // = ThemeColor.b100,
    stroke = false,
    size = 'info',
    ...rest
  }) => {
    const fill = useThemeColor(color)
    const { height, width } = getSize(size)
    const strokeColor = stroke ? fill : 'none'
    return (
      <Component
        fill={fill}
        height={height}
        stroke={strokeColor}
        width={width}
        {...rest}
      />
    )
  }

  SvgWithProps.propTypes = {
    color: themeColorPropType,
    size: propTypeFromEnumKeys(sizeMap),
    stroke: PropTypes.bool,
  }

  return SvgWithProps
}
