import { FormCheckBox, FormInput, WithGap } from '@/components'
import { inputContact, viewResults } from '@/ga'
import { useFormContext, useOnNextClick } from '@/providers'
import { useNotify } from '@/services'
import { anyPropMissing } from '@/utils'
import { isEmpty, not, pick, pipe, values as valuesR } from 'ramda'
import { useCallback } from 'react'
import {
  StepContainer,
  FormContent,
  Step2TitleContainer,
  FormContentBouble,
} from '../styles'

const formItemNames = {
  name: 'name',
  email: 'email',
  phone: 'phone',
}

const names = valuesR(formItemNames)

export const Step2 = () => {
  const { errors, values, setFieldError, validateField } = useFormContext()
  const { mutateAsync } = useNotify()

  const callback = useCallback(
    () =>
      new Promise((resolve, reject) => {
        if (
          pipe(pick(names), isEmpty, not)(errors) ||
          anyPropMissing(names, values)
        ) {
          validateField(formItemNames.name)
          validateField(formItemNames.phone)
          validateField(formItemNames.email)
          reject()
        } else if (values.terms)
          mutateAsync(values)
            .then(() => {
              inputContact()
              viewResults()
              resolve()
            })
            .catch(reject)
        else {
          setFieldError('terms', 'Du musst unsere AGBs akzeptieren')
          reject()
        }
      }),
    [values],
  )

  useOnNextClick(callback)

  return (
    <StepContainer>
      <Step2TitleContainer>
        Optimal versichert zum fairsten Preis
        <br />
        – dank <strong>unbestechlicher Technologie. 🚀</strong>
      </Step2TitleContainer>
      <WithGap gap={6}>
        <FormContentBouble width="400px">
          <FormInput
            placeholder="Dein Name"
            name={formItemNames.name}
            label="Name*"
          />
          <FormInput
            placeholder="+49 172 123123"
            name={formItemNames.phone}
            label="Telefonnummer*"
          />
        </FormContentBouble>
        <FormContent width="400px">
          <FormInput
            placeholder="Deine Email"
            name={formItemNames.email}
            label="Email*"
          />
        </FormContent>
        <FormContent width="400px">
          <FormCheckBox
            name="terms"
            label="Ich stimme die AGB und Datenschutzerklärung zu"
          />
        </FormContent>
      </WithGap>
    </StepContainer>
  )
}
