import PropTypes from 'prop-types'

export const Image = ({ name, type = 'svg', ...props }) => (
  <img alt={`${name} icon`} src={`/assets/${name}.${type}`} {...props} />
)

Image.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['svg', 'png', 'jpg', 'jpeg', 'gif']),
}
