import { Column, Row } from '@/components'
import { ThemeColor } from '@/enums'
import { themeColor, toThemePixels } from '@/utils'
import styled from 'styled-components'
import { prop } from 'styled-tools'

export const Container = styled.div`
  padding: 0 ${toThemePixels(40)};
  min-height: calc(100vh - 314px);
  @media (max-width: 768px) {
    padding: 0 ${toThemePixels(5)};
  }
`

export const StepContainer = styled(Column)`
  width: 740px;
  align-items: center;
  gap: ${toThemePixels(12)};
  @media (max-width: 768px) {
    width: 100vw !important;
  }
`
export const FormContent = styled(Row)`
  display: flex;
  gap: ${toThemePixels(5)};
  width: ${prop('width') || '100%'};
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100vw !important;
    padding: 0 ${toThemePixels(5)};
  }
`
export const FormContentBouble = styled(Row)`
  display: flex;
  gap: ${toThemePixels(5)};
  width: ${prop('width') || '100%'};
  @media (max-width: 768px) {
    width: 100vw !important;
    padding: 0 ${toThemePixels(5)};
  }
`

export const StepCardWithInfoContainer = styled.div`
  background-color: #fff;
  padding: 55px 18px 0;

  span {
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    display: block;
    font-weight: 400;
    margin-bottom: 36px;
    color: ${themeColor(ThemeColor.b100)};
  }
`
export const Card = styled.div`
  border-radius: ${toThemePixels(2)};
  overflow: hidden;
  @media (max-width: 768px) {
    width: 90%;
  }
`

export const CardHeader = styled(Row)`
  align-items: flex-end;
  justify-content: center;
  padding: 40px 0 60px;
  background-color: #edf1ff;
  color: ${themeColor(ThemeColor.primary1)};
  position: relative;
  font-size: 20px;

  div {
    font-size: 14px;
    font-weight: 400;
  }

  > span {
    font-weight: 400;
    font-size: 40px;
    line-height: 42px;
    margin-right: 14px;
    margin-left: 6px;
  }
`

export const AbsoluteTag = styled.div`
  position: absolute;
  bottom: -25px;
  background-color: #ffe600;
  border-radius: 70px;
  font-size: 18px;
  line-height: 21px;
  color: ${themeColor(ThemeColor.b100)};
  padding: 14px 50px;
`

export const CollapseHeader = styled(Row)`
  justify-content: space-between;
  width: 95%;

  div:first-child {
    display: flex;
    align-items: center;
    font-weight: 400;
    gap: 16px;
    font-size: 18px;
    line-height: 21px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: unset;
    div:first-child {
      display: flex;
      align-items: center;
      font-weight: 400;
      gap: 16px;
      font-size: 14px;
      line-height: 21px;
    }
  }
`

export const Step1TitleContainer = styled.div`
  width: 400px;
  text-align: center;
  font-size: 44px;
  line-height: 63px;

  div {
    margin-top: 10px;

    span {
      font-size: 18px;
      line-height: 21px;
    }
  }
  @media (max-width: 768px) {
    font-size: 36px;
    line-height: 42px;
  }
`

export const Step2TitleContainer = styled.div`
  text-align: center;
  font-size: 43px;
  line-height: 56px;
  @media (max-width: 768px) {
    font-size: 30px;
    line-height: 42px;
  }
`

export const Step3TitleContainer = styled.div`
  text-align: center;
  font-size: 43px;
  line-height: 56px;
  @media (max-width: 768px) {
    font-size: 36px;
    line-height: 42px;
  }
`

export const PriceTag = styled.div`
  color: ${themeColor(ThemeColor.b100)};
  font-size: 18px;
  line-height: 21px;
  background-color: #edf1ff;
  border-radius: 60px;
  padding: ${toThemePixels(2)} ${toThemePixels(4)};
  @media (max-width: 768px) {
    width: fit-content;
    font-size: 14px;
    line-height: 16px;
  }
`
