import { curry } from 'ramda'

export const getLocalStorageItem = curry((key) => {
  const item = localStorage.getItem(key)
  return item ? JSON.parse(item) : undefined
})

export const setLocalStorageItem = curry((key, data) =>
  localStorage.setItem(key, JSON.stringify(data)),
)
