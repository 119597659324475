import { ThemeColor } from '@/enums'
import { CheckLine } from '@/icons'
import PropTypes from 'prop-types'
import { Typography } from '../Typography'
import { OuterContainer, InnerContainer } from './styles'

export const CheckBox = ({ checked, disabled, onChange, label, ...rest }) => (
  <OuterContainer
    onClick={() => {
      if (!disabled) onChange?.(checked)
    }}
  >
    <InnerContainer {...rest} checked={checked} disabled={disabled}>
      {checked && <CheckLine color={ThemeColor.b0} size="tag" />}
    </InnerContainer>
    {label && (
      <Typography variant="placeholder" color={ThemeColor.b0}>
        {label}
      </Typography>
    )}
  </OuterContainer>
)

CheckBox.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
}
