import { PageLayout } from '@/components'
import { mapWithKey } from '@/utils'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { routes } from './routes'
import { ScrollToTop } from './ScrollToTop'

export const BaseRouter = () => (
  <BrowserRouter>
    <ScrollToTop />
    <Switch>
      <PageLayout>{mapWithKey(Route, 'path', routes)}</PageLayout>
    </Switch>
  </BrowserRouter>
)
