import styled, { keyframes } from 'styled-components'
import { ifProp } from 'styled-tools'

const loading = keyframes`
  50% {
    filter: brightness(300%);
    animation-timing-function: ease-in;
  }
  100% {
    filter: brightness(100%);
    animation-timing-function: ease-in;
  }
`

export const Container = styled.div.attrs({
  'data-testid': 'loader',
})`
  width: 100%;
  height: ${ifProp('fill', '100%', '100vh')};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  position: ${ifProp('fill', 'absolute', 'fixed')};
  left: 0;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(20px);

  img {
    width: ${ifProp('fill', '50px', '100px')};
    animation: ${loading} 1.5s infinite;
  }
`
