import { ThemeColor } from '@/enums'
import { themeColor, toThemePixels } from '@/utils'
import styled, { css, keyframes } from 'styled-components'
import { switchProp } from 'styled-tools'

const topStyles = css`
  bottom: calc(100% + 30px);
  left: 50%;
  transform: translateX(-50%);
  &:after {
    top: calc(100% + 0px);
    left: 50%;
    transform: translateX(-50%);
    border-right: solid 10px transparent;
    border-left: solid 10px transparent;
    border-top: 10px solid #fff;
  }
`
const rightStyles = css`
  left: calc(100% + 30px);
  top: 50%;
  transform: translateY(-50%);
  &:after {
    left: -10px;
    top: calc(50% - 10px);
    border-right: solid 10px #fff;
    border-bottom: solid 10px transparent;
    border-top: solid 10px transparent;
  }
`
const leftStyles = css`
  right: calc(100% + 30px);
  top: 50%;
  transform: translateY(-50%);
  &:after {
    right: -10px;
    top: calc(50% - 10px);
    border-left: solid 10px #fff;
    border-bottom: solid 10px transparent;
    border-top: solid 10px transparent;
  }
`
const bottomStyles = css`
  top: calc(100% + 30px);
  left: 50%;
  transform: translateX(-50%);
  &:after {
    bottom: calc(100% + 0px);
    left: 50%;
    transform: translateX(-50%);
    border-right: solid 10px transparent;
    border-left: solid 10px transparent;
    border-bottom: 10px solid #fff;
  }
`
const showTooltip = keyframes`
  from {
    opacity:0
  }
`

export const Container = styled.div`
  position: relative;
`

export const HoverContent = styled.div`
  background-color: ${themeColor(ThemeColor.b0)};
  position: absolute;
  z-index: 10000;
  border-radius: 5px;
  padding: ${toThemePixels(4)};
  animation: ${showTooltip} 0.4s linear;
  color: ${themeColor(ThemeColor.b100)};
  min-width: 300px;

  ${switchProp('position', {
    bottom: bottomStyles,
    top: topStyles,
    left: leftStyles,
    right: rightStyles,
  })};

  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
  }
`
