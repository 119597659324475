import {NavList} from "@/components/molecules/NavList";
import {StyledMenu} from "@/components/molecules/BurgerMenu/styles";
import {bool} from "prop-types";

export const BurgerMenu = ({open}) => {
  return (
    <StyledMenu open={open}>
      <NavList/>
    </StyledMenu>
  )
}
BurgerMenu.propTypes = {
  open: bool.isRequired,
}
