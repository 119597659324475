import { Navbar, Footer } from '@/components'
import { useGoToPath } from '@/utils'
import { useFormContext } from '@/providers'
import { startCampaign } from '@/ga'
import { Welcome } from './Welcome'
import { USPS } from './USPS'
import { Warum } from './Warum'
import { StyledButton } from './styles'
import { Testimonials } from './Testimonials'
import {BurgerMenu} from "@/components/molecules/BurgerMenu";
import {useState} from "react";

export const Home = () => {
  const { value, validateField } = useFormContext()

  const goToCampaign = useGoToPath('/insurance-check')

  const handleClick = () => {
    if (value('business')) {
      startCampaign('a')
      goToCampaign()
    } else {
      validateField('business')
      window.scroll({
        top: document.getElementById('business').offsetTop - 120,
        behavior: 'smooth',
      })
    }
  }

  const [open, setOpen] = useState(false);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Navbar open={open} setOpen={setOpen}/>
      <Welcome />
      <div
        style={{
          backgroundColor: '#EDF1FF',
          paddingBottom: '64px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <USPS />
        <Testimonials />
        <Warum />
        <StyledButton color="success" onClick={handleClick}>
          Heute Starten
        </StyledButton>
      </div>
      <Footer />
    </div>
  )
}
