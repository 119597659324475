import {
  oneOrManyChildrenOfType,
  parseChildrenArray,
  renderableType,
  useClickOutsideEvent,
  useModal,
} from '@/utils'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { Input } from '../../styled'
import { getTextFromOptionValue, selectOptionValuePropType } from './helpers'
import { OptionNode } from './OptionNode'
import {
  Container,
  InputContainer,
  OptionsContainer,
  DropdownIcon,
} from './styles'

export const Option = () => null

Option.propTypes = {
  children: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  icon: renderableType,
  value: selectOptionValuePropType,
}

export const Select = ({
  value = '',
  onSelect,
  onSearch,
  onBlur,
  children,
  error,
  disabled,
  onFocus,
  ...props
}) => {
  const options = parseChildrenArray(children)
  const [ref, setRef] = useState()

  const { isOpen, open, close } = useModal()

  const handleFocus = () => {
    if (!props.disabled) open()
    onFocus?.()
  }

  const handleBlur = () => {
    onBlur?.()
    close()
  }

  const handleSelect = (...e) => {
    onSelect(...e)
    close()
  }

  useClickOutsideEvent(ref, handleBlur)

  return (
    <Container ref={setRef} isOpen={isOpen}>
      <InputContainer error={error}>
        <Input
          readOnly
          error={error}
          onFocus={handleFocus}
          value={getTextFromOptionValue(options, value)}
          {...props}
        />
        <DropdownIcon onClick={handleFocus} />
      </InputContainer>
      {isOpen && (
        <OptionsContainer error={error}>
          {options.map(
            ({
              key,
              value: optionValue,
              children: text,
              icon,
              disabled: optionDisabled,
            }) => (
              <OptionNode
                key={key}
                disabled={disabled || optionDisabled}
                icon={icon}
                onClick={handleSelect}
                selected={optionValue === value}
                text={text}
                value={optionValue}
              />
            ),
          )}
        </OptionsContainer>
      )}
    </Container>
  )
}

export const selectChildrenPropType = oneOrManyChildrenOfType(Option)

Select.propTypes = {
  children: selectChildrenPropType,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  onBlur: PropTypes.func,
  onSearch: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: selectOptionValuePropType,
}
