import { useModal } from './useModal'

export const useListenHover = ({ onHover, onUnhover } = {}) => {
  const { isOpen, open, close } = useModal(false)

  const onMouseEnter = () => {
    open()
    onHover?.()
  }

  const onMouseLeave = () => {
    close()
    onUnhover?.()
  }

  return { isOpen, onMouseEnter, onMouseLeave }
}
