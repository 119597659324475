import {
  values,
  map,
  flip,
  when,
  prop,
  fromPairs,
  toPairs,
  path,
  dissocPath,
  update,
  curry,
  last,
  assocPath,
  dissoc,
  assoc,
  pipe,
  ifElse,
  __,
  pick,
} from 'ramda'
import { notEmptyOrFalsy } from './bool'
import { returns } from './function'
import { pack } from './list'
import { isArray, isObject } from './type'

export const objectLiteral = flip(prop)

export const rename = curry((key, newKey, obj) => {
  const value = prop(key, obj)

  return pipe(dissoc(key), assoc(newKey, value))(obj)
})

export const renamePath = curry((keyPath, newKey, obj) => {
  const value = path(keyPath, obj)

  return pipe(
    dissocPath(keyPath),
    assocPath(update(-1, newKey, keyPath), value),
  )(obj)
})

export const renameBy = curry((key, func, obj) => rename(key, func(key), obj))

export const renamePathBy = curry((keyPath, func, obj) =>
  renamePath(keyPath, func(last(keyPath)), obj),
)

export const renameObjKeysBy = curry((func, obj) => {
  const recursivelyRenameObjKeys = (oldObj = obj, newObj = obj) => {
    const [[key, value], ...rest] = toPairs(oldObj)

    const applyRename = () => {
      const renameByKey = renameBy(key, func)

      if (isArray(value))
        return pipe(
          assoc(key, map(when(isObject, renameObjKeysBy(func)), value)),
          renameByKey,
        )(newObj)

      if (isObject(value))
        return pipe(
          assoc(key, renameObjKeysBy(func, value)),
          renameByKey,
        )(newObj)

      return renameByKey(newObj)
    }

    return when(
      () => rest.length,
      (o) => recursivelyRenameObjKeys(fromPairs(rest), o),
      applyRename(),
    )
  }

  return when(() => isArray(obj), values, recursivelyRenameObjKeys())
})

export const toObject = curry((key, value) => fromPairs([[key, value]]))

export const pickOrAll = curry((list, object) =>
  ifElse(notEmptyOrFalsy, pipe(pack, pick(__, object)), returns(object))(list),
)
