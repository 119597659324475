import { useToggable } from '@/utils'
import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import {
  CollapseBody,
  CollapseHeader,
  CollapseIcon,
  Container,
  CollapseContent,
} from './styles'

export const Collapse = ({
  title,
  children,
  defaultOpened = false,
  onOpen,
}) => {
  const childRef = useRef()
  const [isOpen, , toggle] = useToggable(defaultOpened)

  const onClick = () => {
    if (!isOpen) onOpen?.()
    toggle()
  }

  return (
    <Container>
      <CollapseHeader onClick={onClick}>
        {title}
        <CollapseIcon $isOpen={isOpen} />
      </CollapseHeader>
      <CollapseBody height={childRef.current?.offsetHeight} isOpen={isOpen}>
        <CollapseContent ref={childRef}>{children}</CollapseContent>
      </CollapseBody>
    </Container>
  )
}

Collapse.propTypes = {
  children: PropTypes.node,
  defaultOpened: PropTypes.bool,
  title: PropTypes.string,
}
