import { ThemeFontWeight } from '@/enums'
import { themeColorFrom, themeFontWeight, toThemePixels } from '@/utils'
import styled, { css } from 'styled-components'
import { prop } from 'styled-tools'

const commonStyles = css`
  color: ${themeColorFrom(prop('color'))};
  cursor: inherit;
`

export const H1 = styled.h1`
  ${commonStyles};
  font-weight: ${themeFontWeight(ThemeFontWeight.bold)};
  font-size: ${toThemePixels(10.5)};
  line-height: ${toThemePixels(12)};
  letter-spacing: -0.02em;
`

export const H2 = styled.h2`
  ${commonStyles};
  font-weight: ${themeFontWeight(ThemeFontWeight.medium)};
  font-size: ${toThemePixels(7)};
  line-height: ${toThemePixels(10.5)};
  letter-spacing: -0.02em;
`

export const H3 = styled.h3`
  ${commonStyles};
  font-weight: ${themeFontWeight(ThemeFontWeight.regular)};
  font-size: ${toThemePixels(4.5)};
  line-height: ${toThemePixels(7)};
  letter-spacing: 0.02em;
`

export const H4 = styled.h4`
  ${commonStyles};
  font-weight: ${themeFontWeight(ThemeFontWeight.regular)};
  font-size: ${toThemePixels(5)};
  line-height: ${toThemePixels(6)};
  letter-spacing: 0em;
`

export const H5 = styled.h5`
  ${commonStyles};
  font-weight: ${themeFontWeight(ThemeFontWeight.regular)};
  font-size: ${toThemePixels(4)};
  line-height: ${toThemePixels(6)};
  letter-spacing: 0.02em;
`

export const H6 = styled.h6`
  ${commonStyles};
  font-weight: ${themeFontWeight(ThemeFontWeight.regular)};
  font-size: ${toThemePixels(3.5)};
  line-height: 0.02em;
`

export const CaptionB = styled.span`
  ${commonStyles};
  font-weight: ${themeFontWeight(ThemeFontWeight.bold)};
  font-size: ${toThemePixels(3)};
  line-height: ${toThemePixels(4.5)};
  letter-spacing: 0.03em;
`

export const CaptionR = styled.span`
  ${commonStyles};
  font-weight: ${themeFontWeight(ThemeFontWeight.regular)};
  font-size: ${toThemePixels(3)};
  line-height: ${toThemePixels(4.5)};
  letter-spacing: 0.03em;
`

export const P1Body = styled.span`
  ${commonStyles};
  font-weight: ${themeFontWeight(ThemeFontWeight.regular)};
  font-size: ${toThemePixels(3.5)};
  line-height: ${toThemePixels(5)};
  letter-spacing: 0.02em;
`

export const P2Body = styled.span`
  ${commonStyles};
  font-weight: ${themeFontWeight(ThemeFontWeight.light)};
  font-size: ${toThemePixels(3.5)};
  line-height: ${toThemePixels(5)};
  letter-spacing: 0.03em;
`

export const Button = styled.span`
  ${commonStyles};
  font-weight: ${themeFontWeight(ThemeFontWeight.medium)};
  font-size: ${toThemePixels(3.5)};
  line-height: ${toThemePixels(4)};
  letter-spacing: 0.03em;
`

export const placeholderStyles = css`
  ${commonStyles};
  font-weight: ${themeFontWeight(ThemeFontWeight.regular)};
  font-size: ${toThemePixels(3.5)};
  line-height: ${toThemePixels(4)};
`

export const Placeholder = styled.span`
  ${placeholderStyles}
`

export const InputDescription = styled.span`
  ${commonStyles};
  font-weight: ${themeFontWeight(ThemeFontWeight.regular)};
  font-size: ${toThemePixels(2.5)};
  line-height: ${toThemePixels(3.5)};
  letter-spacing: 0.02em;
`

export const InputLabel = styled.label`
  ${commonStyles};
  font-weight: ${themeFontWeight(ThemeFontWeight.regular)};
  font-size: ${toThemePixels(3.5)};
  line-height: ${toThemePixels(4)};

  margin: 0;
`

export const BadgeText = styled.span`
  ${commonStyles};
  font-weight: ${themeFontWeight(ThemeFontWeight.medium)};
  font-size: ${toThemePixels(3)};
  line-height: ${toThemePixels(3)};
  letter-spacing: 0em;
`
