import { ThemeColor } from '@/enums'
import {
  primaryColorFromComponentColor,
  secondaryColorFromComponentColor,
  themeColor,
  themeTransition,
  toThemePixels,
} from '@/utils'
import { identity } from 'ramda'
import styled, { css } from 'styled-components'
import { ifNotProp, switchProp } from 'styled-tools'

const getHorizontalPadding = (multiplier) =>
  ifNotProp({ variant: 'link' }, toThemePixels(multiplier), 0)

const getColor1FromColor = primaryColorFromComponentColor(ThemeColor.primary1)

const getColor2FromColor = secondaryColorFromComponentColor(ThemeColor.primary2)

const getBorderTypeFromVariant = switchProp('variant', {
  outlined: 'solid',
  dashed: 'dashed',
})

const applyContentColor = (color, modifier = identity) => css`
  color: ${modifier(color)};
  fill: ${modifier(color)};
  stroke: ${modifier(color)};
`

const disabledStyles = css`
  ${applyContentColor(ThemeColor.b50, themeColor)}
  border: 1px ${getBorderTypeFromVariant} ${themeColor(ThemeColor.b50)};
  background-color: ${themeColor(ThemeColor.background2)};
`

const containedStyles = css`
  ${applyContentColor(ThemeColor.b0, themeColor)}
  background-color: ${getColor1FromColor};

  &:hover:not(:disabled) {
    background-color: ${getColor2FromColor};
  }
  &:disabled {
    ${disabledStyles}
  }
`

const outlinedStyles = css`
  ${applyContentColor(getColor1FromColor)}
  background-color: ${themeColor(ThemeColor.b0)};
  border: 1px ${getBorderTypeFromVariant} ${getColor1FromColor};
  &:hover:not(:disabled) {
    background-color: ${getColor1FromColor};
    color: white;
  }
  &:disabled {
    ${disabledStyles}
  }
`

const linkStyles = css`
  ${applyContentColor(getColor1FromColor)}
  &:hover:not(:disabled) {
    ${applyContentColor(getColor2FromColor)}
  }
  &:disabled {
    background-color: transparent;
    ${applyContentColor(ThemeColor.b50)}
  }
`

const textStyles = css`
  ${applyContentColor(getColor1FromColor)}
  background-color: ${getColor2FromColor};
  &:hover:not(:disabled) {
    opacity: 0.8;
  }
  &:disabled {
    ${applyContentColor(ThemeColor.b50, themeColor)}
  }
`

const ghostStyles = css`
  ${applyContentColor(themeColor(ThemeColor.b0))}
  background-color: transparent;
  opacity: 0.5;
  &:hover:not(:disabled) {
    opacity: 1;
  }
  &:disabled {
    ${applyContentColor(ThemeColor.b50, themeColor)}
  }
`

const applyVariantStyles = switchProp(
  'variant',
  {
    contained: containedStyles,
    outlined: outlinedStyles,
    dashed: outlinedStyles,
    link: linkStyles,
    text: textStyles,
    ghost: ghostStyles,
  },
  containedStyles,
)

export const buttonBaseStyles = css`
  //reset button defaults
  height: 50px;
  width: fit-content;
  background: none repeat scroll 0 0 transparent;
  border: medium none;
  border-spacing: 0;
  list-style: none outside none;
  margin: 0;
  text-align: left;
  text-decoration: none;
  text-indent: 0;
  user-select: none;
  transition: ${themeTransition()};

  border-radius: ${toThemePixels(2)};

  padding: ${toThemePixels(3)} ${getHorizontalPadding(6)};
  ${applyVariantStyles}

  &:hover:not(:disabled) {
    cursor: pointer;
  }
`

export const Container = styled.button`
  ${buttonBaseStyles}
`
