import {
  dynamicArrayPropType,
  dynamicObjectPropType,
  isObject,
  isString,
} from '@/utils'
import PropTypes from 'prop-types'
import { values } from 'ramda'

export const getError = (error) => {
  if (isString(error)) return error

  if (isObject(error)) return values(error)[0]

  return null
}

export const formItemValuePropType = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.string,
  PropTypes.bool,
  dynamicObjectPropType,
  dynamicArrayPropType,
])

export const formItemPropType = {
  initialValue: formItemValuePropType,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
}
