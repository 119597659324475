import { fromEventTarget } from '@/utils'
import PropTypes from 'prop-types'
import { pipe, when } from 'ramda'
import { useCallback } from 'react'
import { Input } from '../../styled'
import { FormItem } from './Component'
import { formItemPropType } from './helpers'

export const FormInput = ({
  name,
  label,
  type = 'text',
  initialValue,
  info,
  ...props
}) => {
  const handleChange = (onChange) =>
    fromEventTarget(
      pipe(
        when(() => type === 'number', parseFloat),
        onChange,
      ),
    )

  const Component = useCallback(
    ({ onChange, ...field }) => (
      <Input
        onChange={handleChange(onChange)}
        type={type}
        {...field}
        {...props}
      />
    ),
    [],
  )

  return (
    <FormItem info={info} initialValue={initialValue} label={label} name={name}>
      <Component />
    </FormItem>
  )
}

FormInput.propTypes = {
  type: PropTypes.oneOf(['text', 'number']),
  ...formItemPropType,
}
