import { Column, Row } from '@/components'
import { ThemeColor } from '@/enums'
import { themeColor, toThemePixels } from '@/utils'
import styled from 'styled-components'

export const Container = styled(Column)`
  padding: ${toThemePixels(16)} ${toThemePixels(40)};
  min-height: calc(100vh - 314px);
  align-items: center;

  img {
    width: 360px;
  }

  @media (max-width: 768px) {
    padding: 84px 0 60px;

    > ${Row} {
      flex-direction: column;

      > ${Column} {
        gap: 20px;
      }
    }
  }
`

export const ScheduleItem = styled.a`
  width: 360px;
  color: white;
  display: block;
  border: 1px solid white;
  border-radius: 16px;
  padding: 24px 84px 24px 24px;

  font-weight: 400;
  font-size: 24px;
  line-height: 28px;

  &:hover {
    border-color: #40a9ff;
  }

  span {
    font-size: 14px;
    line-height: 16px;
    margin-left: 94px;
  }
`

export const IconContainer = styled.div`
  min-width: 74px;
  width: 74px;
  height: 74px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 50%;
`

export const ImageContainer = styled.div`
  position: relative;
`

export const OpenHours = styled.div`
  background-color: white;
  border-radius: 170px;
  padding: 14px 34px 14px 54px;
  color: ${themeColor(ThemeColor.b100)};
  position: absolute;
  bottom: 18px;
  right: 18px;

  &:before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${themeColor(ThemeColor.success1)};
    top: 20px;
    left: 24px;
  }
`

export const Title = styled.div`
  font-size: 48px;
  line-height: 56px;
  text-align: center;
  margin-bottom: 68px;

  strong {
    font-weight: 600;
  }

  @media (max-width: 768px) {
    font-size: 36px;
    line-height: 42px;
  }
`
