import { toThemePixels } from '@/utils'
import styled from 'styled-components'
import { Column, Row } from '../../styled'

export const Container = styled(Column)`
  position: relative;
  align-items: center;
  gap: ${toThemePixels(8)};
  padding: ${toThemePixels(4)} 0;
  width: 100%;
`
export const ButtonContainer = styled(Row)`
  width: 100%;
  margin-top: ${toThemePixels(10)};
  gap: ${toThemePixels(3)};
  justify-content: center;
`
