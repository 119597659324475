import styled from 'styled-components';

export const StyledMenu = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: white;
  width: 100vw;
  text-align: left;
  padding: 1.7rem;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  transform: ${({ open }) => open ? 'translateY(40%)' : 'translateY(-140%)'};
  z-index: 20000;
`;