import { InfoCircleLine } from '@/icons'
import { renderableType } from '@/utils'
import PropTypes from 'prop-types'
import { StyledTooltip } from './styles'

export const InfoTooltip = ({ content, size, ...props }) => (
  <StyledTooltip content={content} {...props}>
    <InfoCircleLine size={size} />
  </StyledTooltip>
)

InfoTooltip.propTypes = {
  content: renderableType.isRequired,
  size: PropTypes.oneOf(['button', 'info', 'menu', 'header', 'tag', 'title']),
}
