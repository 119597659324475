import { Column, FormCurrency } from '@/components'
import { inputContent, inputRevenue } from '@/ga'
import { useFormContext, useOnNextClick } from '@/providers'
import { anyPropMissing } from '@/utils'
import { isEmpty, not, pick, pipe, values as valuesR } from 'ramda'
import { useCallback } from 'react'
import { StepContainer, FormContent, Step1TitleContainer } from '../styles'

const formItemNames = {
  revenue: 'revenue',
  contentValue: 'contentValue',
}

const names = valuesR(formItemNames)

export const Step1 = () => {
  const { errors, values, validateField } = useFormContext()

  const callback = useCallback(
    () =>
      new Promise((resolve, reject) => {
        if (
          pipe(pick(names), isEmpty, not)(errors) ||
          anyPropMissing(names, values)
        ) {
          validateField(formItemNames.contentValue)
          validateField(formItemNames.revenue)
          reject()
        } else resolve()
      }),
    [values, errors],
  )

  useOnNextClick(callback)

  return (
    <StepContainer>
      <Step1TitleContainer>
        Rahmenbedingungen deines <strong>Betriebs</strong>🔒
        <Column>
          <span>Beantworte uns diese zwei Fragen zu deinem</span>
          <span>Betrieb und es kann losgehen:</span>
        </Column>
      </Step1TitleContainer>
      <FormContent width="740px">
        <FormCurrency
          onBlur={() => inputRevenue(values.revenue)}
          currency="€"
          placeholder="Umsatzsumme eingeben"
          info="Wie hoch wird Dein Umsatz in diesem Jahr ca. sein?"
          name={formItemNames.revenue}
          label="Umsatzsumme*"
        />
        <FormCurrency
          onBlur={() => inputContent(values.contentValue)}
          currency="€"
          placeholder="Inhaltssumme eingeben"
          info="Was ist der Neuwert deiner Betriebsinhalte (Einrichtung, Waren/Vorräte)?"
          name={formItemNames.contentValue}
          label="Inhaltssumme*"
        />
      </FormContent>
    </StepContainer>
  )
}
