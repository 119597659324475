import PropTypes, {
  arrayOf,
  elementType,
  node,
  oneOfType,
  oneOf,
} from 'prop-types'
import { keys, values } from 'ramda'
import { childrenToArray } from './react'
import { concat } from './string'
import { isNumber, isUndefined } from './type'

export const renderableType = oneOfType([elementType, node])

export const oneOrManyChildren = oneOfType([
  renderableType,
  arrayOf(renderableType),
])

export const oneChild = renderableType

export const oneOrManyChildrenOfType = (Component) => (
  props,
  propName,
  componentName,
) => {
  const prop = props[propName]
  return (
    childrenToArray(prop).find((child) => child.type !== Component) &&
    new Error(`${componentName} only accepts ${Component.name} elements`)
  )
}

export const rangePropType = (min, max, required = true) => (
  props,
  propName,
  componentName,
) => {
  let error
  const prop = props[propName]

  if (isUndefined(prop) && required) error = ' is required!'

  if (!isNumber(prop)) error = ' is not a number!'

  if (prop > max || prop < min)
    error = concat(' is not within ', min, ' and ', max)

  return error && new Error(concat(propName, ' in ', componentName, error))
}

export const propTypeFromEnumValues = (e) => oneOf(values(e))
export const propTypeFromEnumKeys = (e) => oneOf(keys(e))

// reassigning this to make it easier to know that PropTypes.object was used intentionally, and not lazily
export const dynamicObjectPropType = PropTypes.object
export const dynamicArrayPropType = PropTypes.array
export const anyPropType = PropTypes.any
