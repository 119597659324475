import { Footer, Header, Stepper } from '@/components'
import { useFormContext } from '@/providers'
import { useGoBack } from '@/utils'
import { useEffect } from 'react'
import { steps } from './steps'
import { Container } from './styles'

export const Configurator = () => {
  const { value } = useFormContext()
  const handleCancel = useGoBack()

  useEffect(() => {
    if (!value('business')) handleCancel()
  }, [])

  return (
    <>
      <Header centered />
      <Container>
        <Stepper steps={steps} onCancel={handleCancel} />
      </Container>
      <Footer />
    </>
  )
}
