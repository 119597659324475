import { Row } from '@/components'
import { ComponentColors } from '@/enums'
import { StepperProvider, useStepperStore } from '@/providers'
import { renderableType } from '@/utils'
import PropTypes from 'prop-types'
import { inc, dec } from 'ramda'
import { useState } from 'react'
import { Button, LineProgress } from '../../atoms'
import { Container, ButtonContainer } from './styles'

const Component = ({ steps, initialStep = 0, onCancel }) => {
  const { onNextClick } = useStepperStore()
  const [step, setStep] = useState(initialStep)

  const isLast = step + 1 === steps.length

  const handleBack = () => {
    if (step) setStep(dec)
    else onCancel()
  }

  const handleNext = async () => {
    try {
      await onNextClick?.()
    } catch (e) {
      return
    }
    setStep(inc)
  }

  const Step = steps[step]

  return (
    <Container>
      <Row justifyContent="space-between" width="100%" alignItems="center">
        {step + 1} / {steps.length}
        <LineProgress percentage={(step / steps.length) * 100} />
        <span onClick={onCancel} style={{ cursor: 'pointer' }}>
          Exit
        </span>
      </Row>
      <Step />
      <ButtonContainer>
        <Button
          color={ComponentColors.text}
          onClick={handleBack}
          size="large"
          variant="ghost"
        >
          Zurück
        </Button>
        <Button
          color={ComponentColors.success}
          onClick={handleNext}
          size="large"
          variant="contained"
        >
          {isLast ? 'Jetzt optimieren' : 'Weiter'}
        </Button>
      </ButtonContainer>
    </Container>
  )
}

Component.propTypes = {
  initialStep: PropTypes.number,
  onCancel: PropTypes.func,
  steps: PropTypes.arrayOf(renderableType).isRequired,
}

export const Stepper = (props) => (
  <StepperProvider>
    <Component {...props} />
  </StepperProvider>
)

Stepper.propTypes = Component.propTypes
