import { toThemePixels } from '@/utils'
import styled from 'styled-components'
import { Column } from '../../styled'

export const Container = styled(Column)`
  flex: 1;
  display: flex;
  border-radius: ${toThemePixels(4)};
  box-shadow: 0px 6px 12px rgba(11, 44, 93, 0.05),
    0px 0px 12px rgba(11, 44, 93, 0.05);
  padding: ${toThemePixels(6)};
  gap: ${toThemePixels(1)};
`
export const CardHeader = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  margin-bottom: ${toThemePixels(4)};
`
export const LeftContent = styled.div`
  display: flex;
  gap: ${toThemePixels(4)};
`
