import { returns } from '@/utils'
import { concat, ifElse, __ } from 'ramda'

export const calculator = (values) => {
  let content
  let liability

  const getInsuranceValue = (restaurantVal, barVal, imbissVal, otherVal) =>
    ({
      restaurant: restaurantVal,
      bar: barVal,
      kantine: otherVal,
      eisdiele: otherVal,
      cafe: otherVal,
      imbiss: imbissVal,
      andere: otherVal,
    }[values.business])

  if (values.revenue <= 50000) liability = getInsuranceValue(90, 100, 80, 90)
  else if (values.revenue <= 100000)
    liability = getInsuranceValue(90, 100, 80, 80)
  else if (values.revenue <= 150000) liability = getInsuranceValue(100, 130, 90)
  else if (values.revenue <= 200000)
    liability = getInsuranceValue(100, 130, 100, 100)
  else if (values.revenue <= 250000)
    liability = getInsuranceValue(110, 130, 100, 100)
  else if (values.revenue <= 300000)
    liability = getInsuranceValue(110, 140, 100, 100)
  else if (values.revenue <= 400000)
    liability = getInsuranceValue(120, 130, 120, 100)
  else if (values.revenue <= 500000)
    liability = getInsuranceValue(130, 160, 150, 100)
  else if (values.revenue <= 600000)
    liability = getInsuranceValue(140, 190, 180, 120)
  else if (values.revenue <= 700000)
    liability = getInsuranceValue(150, 220, 210, 140)
  else if (values.revenue <= 800000)
    liability = getInsuranceValue(160, 260, 240, 160)
  else if (values.revenue <= 900000)
    liability = getInsuranceValue(170, 290, 270, 170)
  else if (values.revenue <= 1000000)
    liability = getInsuranceValue(180, 320, 300, 190)
  else if (values.revenue <= 1500000)
    liability = getInsuranceValue(260, 480, 450, 290)
  else liability = getInsuranceValue(340, 640, 600, 390)

  if (values.contentValue <= 50000)
    content = getInsuranceValue(400, 410, 450, 140)
  else if (values.contentValue <= 100000)
    content = getInsuranceValue(400, 770, 780, 280)
  else if (values.contentValue <= 150000)
    content = getInsuranceValue(720, 1000, 2000, 380)
  else if (values.contentValue <= 200000)
    content = getInsuranceValue(720, 1000, 2000, 500)
  else if (values.contentValue <= 300000)
    content = getInsuranceValue(980, 2000, 2000, 800)
  else if (values.contentValue <= 350000)
    content = getInsuranceValue(1000, 3000, 3000, 930)
  else if (values.contentValue <= 400000)
    content = getInsuranceValue(1000, 3000, 3000, 1000)
  else content = getInsuranceValue(2000, 4000, 4000, 1000)

  return { content, liability }
}

export const toEur = (number, prepend = false) =>
  ifElse(
    returns(prepend),
    concat('€ '),
    concat(__, ' €'),
  )(
    String(number)
      .replace(/\D/g, '')
      .replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
  )
