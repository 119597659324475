import {
  curry,
  join,
  adjust,
  concat as concatPairs,
  pipe,
  toUpper,
  includes,
} from 'ramda'
import { modifyParams } from './function'

export const concat = (...strings) => {
  const [str1, str2, ...rest] = strings

  if (strings.length === 1) return str1

  return concat(concatPairs(String(str1), str2), ...rest)
}

export const capitalize = pipe(String, adjust(0, toUpper), join(''))

export const includesIgnoringCase = curry((pattern, string) =>
  modifyParams(includes, toUpper, [pattern, string]),
)

export const pluralize = (count, singular, plural, showCount = true) => {
  const text = count !== 1 ? plural : singular

  if (showCount) return `${count} ${text}`

  return text
}
