import { ThemeColor } from '@/enums'
import { themeColor, themeTransition, toPixels, toThemePixels } from '@/utils'
import { pipe } from 'ramda'
import styled, { css } from 'styled-components'
import { ifNotProp, ifProp, prop } from 'styled-tools'
import { placeholderStyles } from '../atoms/Typography/styles'

/* PLOP_INJECT_EXPORT */

export const ListItem = styled.li`
  &::marker {
    color: ${themeColor(ThemeColor.primary1)};
    font-size: ${toThemePixels(4)};
  }
`

export const Flex = styled.div`
  display: flex;
  flex-direction: ${prop('flexDirection')};
  flex: ${prop('flex')};
  justify-content: ${prop('justifyContent')};
  align-items: ${prop('alignItems')};
  gap: ${prop('gap')};
  width: ${prop('width')};
`

export const PageLayout = styled.div`
  min-height: 100vh;
  padding-top: 84px;
  background-color: ${themeColor(ThemeColor.primary1)};
`

export const Circle = styled.circle.attrs(({ size, strokeWidth }) => ({
  r: size / 2,
  cx: (2 * size) / 3,
  cy: (2 * size) / 3,
  strokeWidth: toPixels(strokeWidth),
  strokeDasharray: Math.PI * size,
  strokeLinecap: 'round',
}))``

export const Square = styled.div`
  width: ${pipe(prop('$size'), toPixels)};
  height: ${pipe(prop('$size'), toPixels)};
`
export const Row = styled(Flex).attrs({ flexDirection: 'row' })``

export const Column = styled(Flex).attrs({ flexDirection: 'column' })``

export const WithGap = styled(Column)`
  gap: ${pipe(prop('gap'), toThemePixels)};

  @media (max-width: 768px) {
    Row {
      display: flex;
      flex-direction: column;
    }
  }
`

export const FlexEnd = styled(Row).attrs({ justifyContent: 'flex-end' })``

const eventStyles = css`
  &:hover {
    border: 1px solid ${themeColor(ThemeColor.primary1)};
  }

  &:focus {
    color: ${themeColor(ThemeColor.b100)};
  }
`

export const inputBaseStyles = css`
  outline: none;
  user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;

  width: inherit;
  padding: ${toThemePixels(4)};
  border-radius: ${toThemePixels(2)};

  background-color: rgba(255, 255, 255, 0.15);
  border: 1px solid
    ${ifProp(
      'error',
      themeColor(ThemeColor.danger1),
      themeColor(ThemeColor.b0),
    )};

  color: ${ifProp(
    'error',
    themeColor(ThemeColor.danger1),
    themeColor(ThemeColor.b0),
  )};
  box-sizing: border-box;

  transition: ${themeTransition()};

  ${placeholderStyles}

  &:focus {
    background-color: ${themeColor(ThemeColor.b0)};
  }

  &::placeholder {
    color: ${themeColor(ThemeColor.b0)};
    ${placeholderStyles}
  }

  ${ifNotProp('error', eventStyles)}

  &:disabled {
    border: 1px solid transparent;
    ${placeholderStyles}
  }
`

export const Input = styled.input`
  ${inputBaseStyles}
`
