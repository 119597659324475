import { Testimonial, Author, StyledCarousel } from './styles'

export const Testimonials = () => (
  <StyledCarousel
    swipe
    autoPlay
    indicators
    stopAutoPlayOnHover
    navButtonsAlwaysInvisible
    interval={8000}
    animation="slide"
    activeIndicatorIconButtonProps={{
      style: {
        opacity: 1,
      },
    }}
    indicatorIconButtonProps={{
      style: {
        color: '#2E5CFF',
        opacity: 0.5,
      },
    }}
  >
    {[
      {
        title: (
          <div>
            “Mit <strong>SureIn</strong> konnte ich mein Anliegen{' '}
            <strong>schnell und einfach</strong> erledigen und habe dabei sogar
            noch Geld gespart. ✌️”
          </div>
        ),
        name: 'Philipp Niegisch',
        url: 'https://uploads-ssl.webflow.com/6220d2ff5c3d6a706ab5e7f3/6220eb40329c74711c8fb4de_Testimonial_PhilippNiegisch%402x.jpg',
      },
      {
        title: (
          <div>
            “Seit wir <strong>SureIn</strong> nutzen, ist es völlig stressfrei,
            den Überblick über das Risikomanagement für unser Cafés{' '}
            <strong>zu behalten</strong>. 💪”
          </div>
        ),
        name: 'Jason Starmer',
        url: 'https://uploads-ssl.webflow.com/6220d2ff5c3d6a706ab5e7f3/6220eb15a75c6b0b306b7b94_Testimonial_TwoTrickPony%402x.jpg',
      },
      {
        title: (
          <div>
            “<strong>SureIn</strong> hat dafür gesorgt, dass ich fortan rund{' '}
            <strong>65% meiner Versicherungsbeiträge einspare</strong>. 🙌”
          </div>
        ),
        name: 'Katharina Zeilinger',
        url: 'https://uploads-ssl.webflow.com/6220d2ff5c3d6a706ab5e7f3/6220eb5cd284af08806d359b_Testimonial_Schokoladenmanufaktur%402x.jpg',
      },
    ].map(({ name, title, url }) => (
      <Testimonial>
        {title}
        <br />
        <Author>
          <img src={url} />
          {name}
        </Author>
      </Testimonial>
    ))}
  </StyledCarousel>
)
