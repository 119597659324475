import styled from 'styled-components'
import { themeColor } from '@/utils'
import { ThemeColor } from '@/enums'

export const StyledBurger = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;

  &:focus {
    outline: none;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
    background: ${themeColor(ThemeColor.primary1)};
  }

  @media (min-width: 768px) {
    display: none;
  }
`
