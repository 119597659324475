import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { isFunction } from '../type'

export const useGoToPath = (path) => {
  const history = useHistory()

  return useCallback(
    (key) => history.push(isFunction(path) ? path(key) : path),
    [path],
  )
}
