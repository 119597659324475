import { renderableType } from '@/utils'
import PropTypes from 'prop-types'
import { Typography } from '../Typography'
import { selectOptionValuePropType } from './helpers'
import { StyledOption } from './styles'

export const OptionNode = ({
  selected,
  value,
  text,
  icon,
  onClick,
  disabled,
}) => (
  <StyledOption
    disabled={disabled}
    onClick={() => {
      if (!disabled) onClick(value)
    }}
    selected={selected}
  >
    {icon && <img alt="option icon" src={icon} />}
    <Typography variant="placeholder">{text}</Typography>
  </StyledOption>
)

OptionNode.propTypes = {
  disabled: PropTypes.bool,
  icon: renderableType,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  text: PropTypes.string.isRequired,
  value: selectOptionValuePropType.isRequired,
}
