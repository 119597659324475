import {
  /* PLOP_INJECT_IMPORT */
  Configurator,
  Home,
  Schedule,
} from '@/pages'
import { paths } from './paths'

export const routes = [
  /* PLOP_INJECT_APPEND */
  {
    path: paths.configurator,
    component: Configurator,
  },
  {
    path: paths.root,
    component: Home,
    exact: true,
  },
  {
    path: paths.schedule,
    component: Schedule,
  },
]
