import axios from 'axios'
import {
  any,
  assoc,
  filter,
  join,
  mergeLeft,
  pipe,
  pluck,
  propEq,
  prop,
  identity,
} from 'ramda'
import { camelToSnakeCase, snakeToCamelCase } from './case'
import { log } from './environment'
import { renameObjKeysBy } from './object'

const assocHeaders = assoc('headers')

export const getAuthToken = () => null

export const createMultiTenantApi = (baseURL, getHeaders = identity) => {
  /**
   * @param {import('axios').AxiosResponse<any>} response
   */
  const responseInterceptor = pipe(
    prop('data'),
    renameObjKeysBy(snakeToCamelCase),
  )
  /**
   * @param {import('axios').AxiosRequestConfig} config
   */
  const authInterceptor = async (config) => {
    const accessToken = await getAuthToken()

    const headers = mergeLeft(config.headers, {
      Authorization: `Bearer ${accessToken}`,
      ...getHeaders(),
    })

    return assocHeaders(headers, config)
  }

  /**
   * @param {import('axios').AxiosRequestConfig} config
   */
  const contentInterceptor = (config) => {
    const headers = mergeLeft(config.headers, {
      'Content-Type': 'application/json',
    })

    return assocHeaders(headers, config)
  }

  /**
   * @param {import('axios').AxiosRequestConfig} config
   */
  const requestInterceptor = (config) => {
    if (!config.data) return config

    const data = renameObjKeysBy(camelToSnakeCase, config.data)

    return assoc('data', data, config)
  }

  /**
   * @param {import('axios').AxiosRequestConfig} config
   */
  const debugInterceptor = (response) => {
    const { config, data } = response

    log(
      config.method.toUpperCase(),
      config.baseURL + config.url,
      config.data || renameObjKeysBy(snakeToCamelCase, data),
    )

    return response
  }

  log('Api baseUrl:', baseURL)

  const multiTenantApi = axios.create({
    baseURL,
  })

  multiTenantApi.interceptors.request.use(authInterceptor)
  multiTenantApi.interceptors.request.use(contentInterceptor)
  multiTenantApi.interceptors.request.use(requestInterceptor)
  multiTenantApi.interceptors.response.use(debugInterceptor)
  multiTenantApi.interceptors.response.use(responseInterceptor)

  return multiTenantApi
}

export const isLoading = propEq('isLoading', true)

export const areLoading = any(isLoading)

export const hasError = any(propEq('isError', true))

export const getErrorMessages = pipe(pluck('error'), filter(Boolean), join(','))

export const mockApiResponse = (data, delay = 10) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(data)
    }, delay)
  })
