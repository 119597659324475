import { ThemeColor } from '@/enums'
import { componentColorPropType } from '@/theme'
import { oneOrManyChildren } from '@/utils'
import PropTypes from 'prop-types'
import { buttonSizePropType, buttonVariantPropType } from '../ButtonBase'
import { Typography } from '../Typography'
import { Container } from './styles'

export const Button = ({
  children,
  icon: Icon,
  color = ThemeColor.success1,
  ...rest
}) => (
  <Container color={color} {...rest}>
    {Icon && <Icon color={color} size="button" />}
    <Typography variant="button">{children}</Typography>
  </Container>
)

Button.propTypes = {
  children: oneOrManyChildren,
  color: componentColorPropType,
  disabled: PropTypes.bool,
  icon: PropTypes.elementType,
  onClick: PropTypes.func,
  reverted: PropTypes.bool,
  size: buttonSizePropType,
  variant: buttonVariantPropType,
}
