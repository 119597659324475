import { ThemeColor } from '@/enums'
import { toThemePixels } from '@/utils'
import styled, { keyframes } from 'styled-components'
import { Typography } from '../../atoms'
import { Column, Row } from '../../styled'

export const Container = styled(Column)`
  gap: ${toThemePixels(2)};
  width: 100%;
`

export const StyledTypography = styled(Typography)`
  margin-left: ${toThemePixels(1)};
  letter-spacing: 0.02em;
  font-weight: 400;
`

const showError = keyframes`
  from {
    transform: translateY(-8px);
    opacity: 0;
  }
`

export const ErrorMessage = styled(StyledTypography).attrs({
  variant: 'inputDescription',
})`
  display: inline-block;
  animation: ${showError} 0.4s;
  color: #fff;
  line-height: 14px;

  svg {
    width: 10px;
    height: 10px;
    margin-right: 4px;
  }
`

export const GhostInput = styled.input.attrs({ type: 'number' })`
  display: none;
`

export const LabelContainer = styled(Row)`
  justify-content: space-between;
`
