import { Breakpoint, ThemeColor, ThemeFontWeight } from '@/enums'
import { createBreakpoints } from './createBreakpoints'

export const theme = {
  color: {
    [ThemeColor.primary1]: '#2E5CFF',
    [ThemeColor.primary2]: 'rgba(52, 97, 255, 0.1);',
    [ThemeColor.success1]: '#2CD3A1',
    [ThemeColor.success2]: '#1FBA8B',
    [ThemeColor.warning1]: '#FFCC47',
    [ThemeColor.warning2]: 'rgba(255, 199, 0, 0.2)',
    [ThemeColor.danger1]: '#FF5656',
    [ThemeColor.danger2]: 'rgba(201, 24, 74, 0.1)',
    [ThemeColor.background1]: '#EDF1FF',
    [ThemeColor.background2]: '#F7F8FF',
    [ThemeColor.b100]: '#0D2344',
    [ThemeColor.b50]: '#4A576A',
    [ThemeColor.b0]: '#fff',
  },
  font: {
    weight: {
      [ThemeFontWeight.light]: '200',
      [ThemeFontWeight.regular]: '400',
      [ThemeFontWeight.medium]: '500',
      [ThemeFontWeight.bold]: '700',
    },
  },
  baseFontSize: 4,
  breakpoints: createBreakpoints({
    [Breakpoint.xs]: 0,
    [Breakpoint.sm]: 600,
    [Breakpoint.md]: 960,
    [Breakpoint.lg]: 1280,
    [Breakpoint.xl]: 1920,
  }),
  transition: 'cubic-bezier(0, 0, 0.2, 1)',
}
