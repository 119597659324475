import { oneOrManyChildren } from '@/utils'
import PropTypes from 'prop-types'
import { Image } from '../../atoms'
import { Container } from './styles'

export const Header = ({ centered = false, children }) => (
  <Container centered={centered}>
    <Image name="logo" type="png" />
    {children}
  </Container>
)

Header.propTypes = {
  centered: PropTypes.bool,
  children: oneOrManyChildren,
}
