import { toThemePixels } from '@/utils'
import styled, { css } from 'styled-components'
import { ifProp } from 'styled-tools'

const horizontalStyles = css`
  flex-wrap: wrap;
  flex-direction: row;
`

const verticalStyles = css`
  flex-direction: column;
`

export const Container = styled.div`
  display: flex;
  gap: ${toThemePixels(3)};
  ${ifProp({ alignment: 'horizontal' }, horizontalStyles)};
  ${ifProp({ alignment: 'vertical' }, verticalStyles)};
`
