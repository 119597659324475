import { useFormContext } from '@/providers'
import { bitwiseNot } from '@/utils'
import PropTypes from 'prop-types'
import { pipe } from 'ramda'
import { useCallback } from 'react'
import { CheckBox } from '../../atoms'
import { FormItem } from './Component'
import { formItemValuePropType } from './helpers'

export const FormCheckBox = ({ name, initialValue, ...props }) => {
  const { setErrors, errors } = useFormContext()

  const Component = useCallback(
    ({ onChange, value, ...field }) => (
      <CheckBox
        checked={Boolean(value)}
        onChange={pipe(bitwiseNot, onChange, () => {
          const { [name]: error, ...rest } = errors
          if (error && !value) setErrors(rest)
        })}
        {...field}
        {...props}
      />
    ),
    [errors[name]],
  )

  return (
    <FormItem initialValue={initialValue} name={name}>
      <Component />
    </FormItem>
  )
}

FormCheckBox.propTypes = {
  initialValue: formItemValuePropType,
  name: PropTypes.string.isRequired,
}
