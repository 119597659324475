import 'antd/dist/antd.css'
import { StrictMode, Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Loader } from './components'
import { initGA } from './ga'
import { ThemeProvider, ReactQueryProvider, FormProvider } from './providers'
import { BaseRouter as App } from './routes'
import { validationSchema } from './validationSchema'

initGA()

ReactDOM.render(
  <StrictMode>
    <Suspense fallback={<Loader />}>
      <ThemeProvider>
        <ReactQueryProvider>
          <FormProvider validationSchema={validationSchema}>
            <App />
          </FormProvider>
        </ReactQueryProvider>
      </ThemeProvider>
    </Suspense>
  </StrictMode>,
  document.getElementById('root'),
)
