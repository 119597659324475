import { Button, Row, WithGap, Image, Column } from '@/components'
import styled from 'styled-components'
import { themeColor } from '@/utils'
import { ThemeColor } from '@/enums'
import Carousel from 'react-material-ui-carousel'

export const StyledCarousel = styled(Carousel)`
  width: 100%;
  padding: 75px 0 110px;
`

export const WelcomeSection = styled.div`
  z-index: 1;
  padding: 130px 120px 140px;
  position: relative;
  > div {
    max-width: 680px;
  }

  p {
    font-weight: 300;
    font-size: 24px;
    line-height: 32px;
  }

  @media (max-width: 895px) {
    padding: 78px 20px 20px;
    overflow: hidden;

    > div {
      max-width: unset;
    }

    p {
      font-weight: 300;
      font-size: 16px;
      line-height: 26px;
      text-align: center;
    }
  }
`

export const WelcomeTitle = styled.div`
  font-weight: 300;
  font-size: 54px;
  line-height: 63px;

  @media (max-width: 895px) {
    font-weight: 300;
    font-size: 36px;
    line-height: 42px;
    text-align: center;
  }
`

export const WelcomeImage = styled(Image).attrs({
  name: 'welcomeImg',
  type: 'png',
})`
  z-index: -1;
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;

  @media (max-width: 895px) {
    float: right;
    margin-right: -20px;
    margin-top: -86px;
    position: static;
    height: 470px;
    z-index: 1;
  }
`

export const WelcomeOptions = styled(Row)`
  @media (max-width: 895px) {
    flex-direction: column;
    button {
      width: 100%;
      text-align: center;
      z-index: 2;
      span {
        width: inherit;
      }
    }
  }
`
export const WelcomeSelect = styled.div`
  width: 300px;
  @media (max-width: 895px) {
    width: unset;
  }
`

export const UspsSection = styled(WithGap).attrs({ gap: 15 })`
  background-color: rgba(13, 35, 68, 0.1);
  padding: 80px 36px;
  width: 100%;

  @media (max-width: 768px) {
    align-items: center;
  }
`

export const SectionTitle = styled.div`
  font-size: 54px;
  line-height: 63px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #0d2344;

  @media (max-width: 768px) {
    width: 100%;
    font-size: 34px;
    line-height: 39px;
    text-align: center;
  }
`

export const UspsContainer = styled(Row)`
  justify-content: space-between;
  color: #0d2344;

  > div {
    font-size: 18px;
    line-height: 24px;

    text-align: center;
    letter-spacing: -0.75px;
    width: 23%;
    display: flex;
    gap: 12px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 24px;

    > div {
      font-size: 18px;
      line-height: 24px;

      text-align: unset;
      letter-spacing: -0.25px;
      width: 100%;
      display: flex;
      gap: 12px;
    }
  }
`

export const WarumSection = styled(Column)`
  color: #0d2344;
  align-items: center;
  text-align: center;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.01em;

  > div:not(:first-child):not(:last-child) {
    margin-top: 24px;
    max-width: 495px;
  }
  @media (max-width: 895px) {
    width: 100%;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
  }
`

export const WraumItemsContainer = styled(Row)`
  margin: 48px 0;
  justify-content: center;
  gap: 30px;

  @media (max-width: 895px) {
    justify-content: flex-start;
    width: 100%;
    padding: 0 20px 20px;
    overflow-x: auto;

    > div {
      min-width: 268px;
    }
  }
`

export const WarumItem = styled(WithGap).attrs({ gap: 4 })`
  height: 384px;
  width: 268px !important;
  align-items: center;
  justify-content: center;
  border: 1px solid #2e5cff;
  border-radius: 16px;
  color: #0d2344;
  padding: 0 18px;

  font-size: 18px;
  line-height: 24px;

  text-align: center;
  letter-spacing: 0.01em;
`

export const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${themeColor(ThemeColor.primary1)};
  width: 74px;
  height: 74px;
  border-radius: 50%;
  font-size: 36px;
  line-height: 22px;
`

export const WarumTitle = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #0d2344;
`

export const StyledButton = styled(Button)`
  width: 268px;
  justify-content: center;
`

export const Testimonial = styled(Column)`
  margin: 0 auto;
  align-items: center;
  max-width: 700px;
  font-weight: 300;
  font-size: 54px;
  line-height: 63px;
  text-align: center;
  letter-spacing: -0.01em;
  color: ${themeColor(ThemeColor.primary1)};
  padding-bottom: 40px;
  strong {
    font-weight: 500;
  }
  @media (max-width: 768px) {
    font-size: 36px;
    line-height: 42px;
    padding: 10px 10px;
  }
`

export const Author = styled(Row)`
  gap: 16px;
  align-items: center;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.01em;
  color: ${themeColor(ThemeColor.b100)};
  img {
    border-radius: 50%;
    width: 48px;
    height: 48px;
    object-fit: cover;
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const Dummy = styled.div`
  display: none;

  @media (max-width: 895px) {
    display: flex;
    width: 100%;
    height: 100px;
    background-color: #edf1ff;
    position: absolute;
    bottom: -1px;
    left: 0;
    z-index: -1;

    div {
      background-color: rgba(13, 35, 68, 0.1);
      width: 100%;
      height: 100px;
    }
  }
`
