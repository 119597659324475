import { toThemePixels } from '@/utils'
import styled from 'styled-components'
import { Row } from '../../styled'

export const Content = styled(Row)`
  align-items: center;
  justify-content: space-between;
  gap: 100px;
`

export const StyledRow = styled(Row)`
  align-items: center;
  gap: ${toThemePixels(2)};
`
