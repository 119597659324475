import { makeStore, noop } from '@/utils'
import { assoc, compose } from 'ramda'
import { useCallback, useEffect, useMemo } from 'react'

const setNextDisabled = (value) => ({
  type: 'NEXT_DISABLED',
  payload: value,
})

const setOnNextClick = (callback) => ({
  type: 'SET_ON_NEXT_CLICK',
  payload: callback,
})

const reducer = (state, action) => {
  switch (action.type) {
    case setNextDisabled().type:
      return assoc('nextDisabled', action.payload, state)
    case setOnNextClick().type:
      return assoc('onNextClick', action.payload, state)
    default:
      return state
  }
}

export const [StepperProvider, useDispatchStepper, useStepperStore] = makeStore(
  {
    nextDisabled: false,
    onNextClick: noop,
  },
  reducer,
)

export const useOnNextClick = (onNextClick) => {
  const callback = useCallback(async () => {
    const pass = await onNextClick()
    return pass
  }, [onNextClick])

  const dispatch = useDispatchStepper()
  const dispatchSetOnNextClick = compose(dispatch, setOnNextClick)

  useEffect(() => {
    dispatchSetOnNextClick(callback)
    return () => {
      dispatchSetOnNextClick(noop)
    }
  }, [callback])
}

export const useNextDisabled = (factory, deps = []) => {
  const nextDisabled = useMemo(() => factory(), deps)
  const dispatch = useDispatchStepper()
  const dispatchSetNextDisabled = compose(dispatch, setNextDisabled)

  useEffect(() => {
    dispatchSetNextDisabled(nextDisabled)
    return () => {
      dispatchSetNextDisabled(false)
    }
  }, [nextDisabled])
}
