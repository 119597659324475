import {
  SectionTitle,
  WarumItem,
  WarumSection,
  WarumTitle,
  WraumItemsContainer,
  Circle,
} from './styles'

export const Warum = () => (
  <WarumSection id="so-funktioniert">
    <SectionTitle>
      Warum <strong>SureIn</strong>
    </SectionTitle>
    <div>
      In nur 3 Minuten zu mehr Klarheit – Versicherungsstatus überprüfen und
      dabei smart & regelmäßig optimieren.
    </div>
    <WraumItemsContainer>
      {[
        {
          icon: '💸',
          title: 'Kostenlos',
          content:
            'Nutze die gratis App und erhalte Dein Dashboard mit allen Informationen wie Versicherungsnummern, Beiträgen und Kündigungsfristen.',
        },
        {
          icon: '💎',
          title: 'Transparent',
          content:
            'Als unabhängiger Berater stehen wir nicht im Dienste einer Versicherung und können Dir daher die besten Angebote von über 70 Anbietern empfehlen.',
        },
        {
          icon: '🤠',
          title: 'Persönlich',
          content:
            'Bei SureIn hast du Full-Service für deine Gastro.\n1. Die App: klare Übersicht & immer den besten Preis\n2. Deinen persönlichen Berater als Ansprechparnter',
        },
      ].map(({ icon, title, content }) => (
        <WarumItem>
          <Circle>{icon}</Circle>
          <WarumTitle>{title}</WarumTitle>
          {content}
        </WarumItem>
      ))}
    </WraumItemsContainer>
  </WarumSection>
)
