/* eslint-disable no-console */
import { complement } from 'ramda'

export const isDev = () => process.env.NODE_ENV === 'development'

export const isProd = complement(isDev)

export const log = (...args) => {
  if (isDev()) {
    console.log(...args)
  }
}

export const warn = (...args) => {
  if (isDev()) {
    console.warn(...args)
  }
}

export const error = (...args) => {
  if (isDev()) {
    console.error(...args)
  }
}

export const logTable = (...args) => {
  if (isDev()) {
    console.table(...args)
  }
}
