import { toEur } from '@/pages/configurator/helpers'
import { fromEventTarget, toInt } from '@/utils'
import PropTypes from 'prop-types'
import { when, pipe, equals, tail, head, concat, replace, trim } from 'ramda'
import { useCallback, useRef } from 'react'
import { Input } from '../../styled'
import { FormItem } from './Component'
import { formItemPropType } from './helpers'
import { GhostInput } from './styles'

export const FormCurrency = ({
  name,
  label,
  currency,
  initialValue,
  info,
  ...props
}) => {
  const ref = useRef()
  const handleChange = (onChange) =>
    fromEventTarget(
      pipe(
        when(pipe(head, equals(currency)), tail),
        trim,
        replace(/\./g, ''),
        toInt,
        onChange,
      ),
    )

  const Component = useCallback(
    ({ onChange, value = '', ...field }) => (
      <Input
        onChange={handleChange(onChange)}
        onKeyDown={ref.current?.onkeydown}
        value={value ? toEur(value, true) : ''}
        {...field}
        {...props}
      />
    ),
    [],
  )

  return (
    <>
      <FormItem
        info={info}
        initialValue={initialValue}
        label={label}
        name={name}
      >
        <Component />
      </FormItem>
      <GhostInput ref={ref} />
    </>
  )
}

FormCurrency.propTypes = {
  currency: PropTypes.string.isRequired,
  ...formItemPropType,
}
