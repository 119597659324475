import { Image } from '../../atoms'
import { DefaultNav, NavContainer, StartButton, TopNavList } from './styles'
import { useFormContext } from '@/providers'
import { useGoToPath } from '@/utils'
import { startCampaign } from '@/ga'
import { Row } from '@/components'
import { Burger } from '@/components/atoms/Burger'
import { BurgerMenu } from '@/components/molecules/BurgerMenu'
import { NavList } from '../NavList'

export const Navbar = ({ open, setOpen }) => {
  const { value, validateField } = useFormContext()

  const goToCampaign = useGoToPath('/insurance-check')

  const handleClick = () => {
    if (value('business')) {
      goToCampaign()
      startCampaign('a')
    } else {
      validateField('business')
      window.scroll({
        top: document.getElementById('business').offsetTop - 120,
        behavior: 'smooth',
      })
    }
  }

  return (
    <NavContainer>
      <DefaultNav>
        <Row>
          <Image
            name="logo-with-text"
            type="png"
            style={{ height: '5vh', margin: '.5rem' }}
          />
          <TopNavList>
            <NavList />
          </TopNavList>
        </Row>
        <Burger open={open} setOpen={setOpen} />
        <StartButton color="success" onClick={handleClick}>
          Jetzt Starten
        </StartButton>
      </DefaultNav>
      <BurgerMenu open={open} />
    </NavContainer>
  )
}
