import { ThemeColor } from '@/enums'
import { themeColor } from '@/utils'
import { createGlobalStyle } from 'styled-components'
import Roboto from './RobotoCondensed-Regular.ttf'

export const createGlobalStyles = () => createGlobalStyle`
@font-face {
  font-family: Roboto;
  src: url(${Roboto}) format('truetype');
}


body {
  margin: 0;
  color: ${themeColor(ThemeColor.b0)};
  font-weight:300;
  letter-spacing: -0.01em;

  strong {
    font-weight:500;
  }

  *[disabled] {
    color: ${themeColor(ThemeColor.b50)};
    cursor: not-allowed !important;
    background: ${themeColor(ThemeColor.background2)};
  }
}
`
