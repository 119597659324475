import { curry, map } from 'ramda'
import { isUndefined } from './type'

export const noop = () => {}

export const returns = (x) => () => x

export const pipeWithResult =
  (...func) =>
  (...p) => {
    const [fn, ...rest] = func

    if (isUndefined(fn)) return p[0]

    return pipeWithResult(...rest)(fn(...p), ...p)
  }

export const modifyParams = curry((fn, modifier, params) =>
  fn(...map(modifier, params)),
)

export const toAll =
  (...func) =>
  (...params) => {
    const [fn, ...rest] = func

    if (isUndefined(fn)) return

    fn(...params)
    toAll(...rest)(...params)
  }
