import { ComponentColors } from '@/enums'
import { componentColorPropType } from '@/theme'
import { cleanPercentage, rangePropType } from '@/utils'
import PropTypes from 'prop-types'
import { Container, InnerContainer } from './styles'

export const LineProgress = ({
  percentage,
  color = ComponentColors.primary,
  width = 200,
}) => (
  <Container width={width}>
    <InnerContainer color={color} width={cleanPercentage(percentage)} />
  </Container>
)

LineProgress.propTypes = {
  color: componentColorPropType,
  percentage: rangePropType(0, 100),
  width: PropTypes.number,
}
