import { toThemePixels } from '@/utils'
import styled from 'styled-components'

export const FooterContainer = styled.div`
  width: 100%;
  background: white;
  display: flex;
  bottom: 0;
  justify-content: space-evenly;
  padding: ${toThemePixels(10)};

  @media (max-width: 768px) {
    display: block;
  }
`

export const Logo = styled.div`
  @media (max-width: 768px) {
    margin: 0 3rem;
  }
`
