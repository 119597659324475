import { Image, Row, Column, Header, Footer } from '@/components'
import { clickBook, clickCall } from '@/ga'
import { CalendarLine } from '@/icons/CalendarLine'
import { PhoneLine } from '@/icons/PhoneLine'
import {
  IconContainer,
  ImageContainer,
  ScheduleItem,
  Container,
  OpenHours,
  Title,
} from './styles'

export const Schedule = () => {
  return (
    <>
      <Header centered />
      <Container>
        <Title>
          Unsere Experten eklären dir dein{' '}
          <strong>Optimierungspotential</strong> 🚀 im Detail
        </Title>
        <Row gap="20px">
          <ImageContainer>
            <Image name="lion" type="jpg" style={{ borderRadius: '15px' }} />
            <OpenHours>Mo - Fr 10 bis 18 Uhr</OpenHours>
          </ImageContainer>
          <Column justifyContent="space-between">
            <ScheduleItem
              onClick={() => clickBook()}
              href={`https://calendly.com/lion-dahlenburg/30min-1`}
            >
              <Row gap="20px">
                <IconContainer>
                  <CalendarLine size="header" />
                </IconContainer>
                Buche einen Termin!
              </Row>
              <span>beratung@surein.de</span>
            </ScheduleItem>
            <ScheduleItem onClick={() => clickCall()} href="tel:+493083798996">
              <Row gap="20px">
                <IconContainer>
                  <PhoneLine size="header" />
                </IconContainer>
                Ruf uns einfach an!
              </Row>
              <span>+49 30 83798996</span>
            </ScheduleItem>
          </Column>
        </Row>
      </Container>
      <Footer />
    </>
  )
}
