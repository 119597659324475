import styled from 'styled-components'
import { themeFontWeight } from '@/utils'
import { ThemeColor, ThemeFontWeight } from '@/enums'

export const ListTitle = styled.li`
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 0.01em;
  margin: 1rem 1rem;
  color: black;
  font-weight: ${themeFontWeight(ThemeFontWeight.bold)};
`

export const ListUrl = styled.a`
  text-decoration: none;
  cursor: pointer;
  color: black;
`

export const ListItem = styled.li`
  margin: 0.25rem 1rem;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.01em;

  ${ListUrl}:hover {
    color: ${ThemeColor.primary1};
  }
`

export const ListText = styled.li`
  margin: 0.5rem 1rem;
  line-height: 1.5rem;
  color: black;
`
