import {
  complement,
  compose,
  concat,
  either,
  isEmpty,
  isNil,
  join,
  map,
  pipe,
  prepend,
  reject,
  toPairs,
  when,
  __,
} from 'ramda'

const toId = concat(__, 'Id')

export const makeCrudPaths = (path, key) => {
  const documentKey = key || toId(path)
  const root = `/${path}`

  return {
    root,
    view: `${root}/view/:${documentKey}`,
    viewWithKey: concat(`${root}/view/`),
    add: `${root}/add`,
    edit: `${root}/edit/:${documentKey}`,
    editWithKey: concat(`${root}/edit/`),
  }
}

export const makeQueryString = compose(
  when(complement(isEmpty), pipe(prepend('?'), join(''))),
  join('&'), // Join each segment of the query with '&'
  map(join('=')), // Join the key-value pairs with '='
  map(map(encodeURIComponent)), // encode keys and values
  toPairs, // convert the object to pairs like `['limit', 5]`
  reject(either(isNil, isEmpty)),
)

export const queryParamsFromString = (search) =>
  search
    ? JSON.parse(
        `{"${search.substring(1).replace(/&/g, '","').replace(/=/g, '":"')}"}`,
        (key, value) => (key === '' ? value : decodeURIComponent(value)),
      )
    : {}
