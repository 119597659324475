import { FooterContainer, Logo } from './styles'
import { DotlessListHorizontal } from '../NavList/styles'
import { NavItem, NavTitle } from '../../atoms/NavItems'
import { Image } from '../../atoms'
import { ListText } from '../../atoms/NavItems/styles'

export const Footer = () => {
  return (
    <FooterContainer>
      <Logo>
        <Image
          name="logo-with-text"
          type="png"
          style={{ height: '8vh', margin: '1rem 0.5rem' }}
        />
      </Logo>

      <DotlessListHorizontal>
        <NavTitle name="Unternehmen" />
        <NavItem name="So funktioniert SureIn" url="#section" />
        <NavItem
          name="Karriere"
          url="https://surein.notion.site/surein/Working-at-SureIn-6c497c7e2ce54979991adc38e062f597"
        />
        <NavItem
          name="Schaden melden"
          url="https://dvqdhnj0xvr.typeform.com/to/M9bY8HEW"
        />
        <NavItem name="Partner" url="https://www.surein.de/partner" />
      </DotlessListHorizontal>
      <DotlessListHorizontal>
        <NavTitle name="Info" />
        <NavItem name="Datenschutz" url="https://www.surein.de/datenschutz" />
        <NavItem name="AGB" url="https://www.surein.de/agb" />
        <NavItem
          name="Erstinformationen"
          url="https://www.surein.de/erstinformation"
        />
        <NavItem name="Impressum" url="https://www.surein.de/impressum" />
      </DotlessListHorizontal>
      <DotlessListHorizontal>
        <NavTitle name="Kontakt" />
        <NavItem
          name={
            <>
              <strong>M</strong> info@surein.de
            </>
          }
          url="mailto:info@surein.de"
        />
        <NavItem
          name={
            <>
              <strong>T</strong> +49 30 83798996
            </>
          }
          url="tel:+493083798996"
        />
        <ListText>Mo. - Fr.: 9 - 18 Uhr</ListText>
      </DotlessListHorizontal>
    </FooterContainer>
  )
}
