import { themeColorPropType } from '@/theme'
import { objectLiteral, oneOrManyChildren, propTypeFromEnumKeys } from '@/utils'
import {
  BadgeText,
  Button,
  CaptionB,
  CaptionR,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  InputDescription,
  InputLabel,
  P1Body,
  P2Body,
  Placeholder,
} from './styles'

export const variants = {
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  h5: H5,
  h6: H6,
  captionB: CaptionB,
  captionR: CaptionR,
  p1Body: P1Body,
  p2Body: P2Body,
  button: Button,
  placeholder: Placeholder,
  inputDescription: InputDescription,
  inputLabel: InputLabel,
  badgeText: BadgeText,
}

const getTypographyVariantComponent = objectLiteral(variants)

export const Typography = ({ variant, ...rest }) => {
  const Component = getTypographyVariantComponent(variant)

  return <Component {...rest} />
}

Typography.propTypes = {
  children: oneOrManyChildren,
  color: themeColorPropType,
  variant: propTypeFromEnumKeys(variants),
}
