import PropTypes from 'prop-types'

export const buttonSizePropType = PropTypes.oneOf(['small', 'medium', 'large'])

export const buttonVariants = [
  'contained',
  'outlined',
  'dashed',
  'link',
  'text',
  'ghost',
]

export const buttonVariantPropType = PropTypes.oneOf(buttonVariants)
