import { toThemePixels } from '@/utils'
import styled from 'styled-components'
import { Button } from '@/components'
import { DotlessList } from '@/components/molecules/NavList/styles'

export const NavContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index:1000;
  background: white;
  padding: 0 ${toThemePixels(30)};

  @media (max-width: 768px) {
    padding: 0 ${toThemePixels(10)};
  }
`

export const DefaultNav = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  z-index: 10000;
`

export const StartButton = styled(Button)`
  @media (max-width: 768px) {
    display: none;
  }
`

export const TopNavList = styled(DotlessList)`
  @media (max-width: 768px) {
    display: none;
  }
`
